import React, {useEffect, useState, UseState} from 'react';
import { Grid, Typography, TextField, Divider,FormControlLabel, FormGroup, Checkbox,Card, CardContent, CardMedia, Button, Chip, Box  } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';


import { getBlogs } from "../apiCalls";

const Blogs = () => {
    const [data, setData] = useState([]);

    const categories = [
        {
          category: "Hardware Categories",
          items: ["CPU", "Monitors", "Printers", "RAM", "Routers", "Storage"]
        },
        {
          category: "Software Categories",
          items: [
            "Accounting",
            "Astrology",
            "Billing & Invoicing",
            "CRM",
            "Collaboration Software",
            "Design Solutions",
            "ERP",
            "Educational",
            "HR",
            "Malware Protection",
            "Petrol Pump",
            "Video Making"
          ]
        },
        {
          category: "Tags",
          items: ["Best Seller", "Newly Added", "Sponsored"]
        },
        {
          category: "Vendor",
          items: [
            "AWS",
            "Busy Infotech",
            "Hewlett Packard",
            "Quick Heal",
            "Tata Teleservices"
          ]
        }
      ];
      
      const count = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

      useEffect( () => {
        const getProduct = async () => {
          try {
            const data = await getBlogs();
            console.log("blooog", data);
            
            setData(data.data);
          } catch (err) {
            // setError('Failed to load testimonials. Please try again later.');
            console.error('Error fetching testimonials:', err);
          }
        }
    
        getProduct()
      }, []);

      const handleBlogDetail = () => {
        window.location.href = '/blog/23';
      }

    return(
        <>
            <Grid sx={{background:'#f8f8f8'}}>
                <Grid container >
                    <Grid 
                        sx={{
                            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(https://png.pngtree.com/thumb_back/fh260/background/20201028/pngtree-abstract-technology-background-technical-electric-image_443494.jpg)',
                            backgroundSize: 'cover', 
                            backgroundPosition: 'center', 
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                        }}
                    >
                        <Grid container justifyContent='flex-start'  direction='column' sx={{width:{xs:'98%',sm:'98%',md:'80%'},margin:'90px auto'}}>
                            <Grid item>
                                <Typography variant='h5' color='#fff' fontWeight='bold'>Blog</Typography>
                            </Grid>
                            <Grid item pt={2}>
                                <Typography variant='subtitle1' color="#BABABA">
                                    Lorem ipsum dolor sit incididunt u id est laborum.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction='column' sx={{width:{xs:'80%',sm:'80%',md:'80%'},margin:'30px auto'}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid container direction='row' justifyContent='space-between'>
                                    <Grid item>
                                        <Typography variant='h6' fontWeight='bold'>Blogs</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='subtitle1'>{data?.length} results</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                                <TextField
                                    id="outlined-basic"
                                    label="search products"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} pt={2} >
                        <Grid container justifyContent='space-between' direction='row'>
                            <Grid item md={12}>
                                <Grid container spacing={2}>
                                    {data?.map((item , index)=> (
                                        <Grid item xs={12} sm={6} md={3} lg={2.4} >
                                             <Grid key={index} sx={{maxWidth:'310px' , marginBottom:'10px', margin:'30px 5px', boxShadow:' rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;',borderRadius:'4%',overflow:'hidden'}}>
                                                    <Grid container justifyContent='space-between' direction='column' >
                                                        <Grid item md={12}>
                                                            <img src={item.image || "https://media.istockphoto.com/id/1947499362/photo/happy-group-of-business-people-discussing-strategy-during-team-meeting-at-the-office-desk.webp?b=1&s=170667a&w=0&k=20&c=AQAPKF3qp3LWmm3F_aQ_TOEghFTvbIVwzuG6etVf18I="} alt="blog" style={{width:'100%' ,minHeight:'120px', maxHeight:'120px'}} />
                                                        </Grid>
                                                        <Grid item md={12} >
                                                            <Grid container direction='column'  spacing={0.8} p={0.9}>
                                                            <Grid item md={12} sx={{ minHeight: '40px', maxHeight: '60px' }}>
                                                                <Typography
                                                                    variant="body2"
                                                                    textAlign="left"
                                                                    fontWeight="bold"
                                                                    sx={{
                                                                    overflow: "hidden",         // Hide the overflowed text
                                                                    textOverflow: "ellipsis",   // Apply ellipsis (...)
                                                                    wordBreak:'break-word'      // Prevent text from wrapping to a new line
                                                                    }}
                                                                >
                                                                    {item.title || "A software subscription is a licensin"}
                                                                </Typography>
                                                                </Grid>
                                                                <Grid item md={12} sx={{ minHeight: '65px', maxHeight: '8px' }}>
                                                                <Typography variant="body2" textAlign="left">
                                                                    In today's fast-paced digital landscape, businesses are constantly seeking ...
                                                                </Typography>
                                                                </Grid>
                                                                <Grid item md={12} sx={{display:'flex' , justifyContent:'flex-start' , alignItems:'center', cursor:'pointer'}} onClick={handleBlogDetail}>
                                                                    <Typography variant='body2' fontWeight='bold' >Explore  </Typography>
                                                                    <KeyboardArrowRightIcon />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                    ))}
          
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default Blogs;