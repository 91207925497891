import React, { useState, useEffect } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { getAllTestimonial } from "../../apiCalls";

const Testimonials = () => {

  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  const fetchTestimonialsWithHandling = async () => {
    try {
      const data = await getAllTestimonial();
      if (data.success) {
        const shuffledArray = [...data?.data].sort(() => 0.5 - Math.random());
        const selectedItems = shuffledArray.slice(0, 3);
        setData(selectedItems);
      }
    } catch (err) {
      setError("Failed to load testimonials. Please try again later.");
      console.error("Error fetching testimonials:", err);
    }
  };

  useEffect(() => {
    fetchTestimonialsWithHandling();
  }, []);

  return (
    <Grid
      sx={{
        backgroundImage:
          "url(https://img.freepik.com/premium-vector/modern-simple-minimal-elegant-white-background-with-shiny-lines_249611-5952.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        padding: "40px 0",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          width: { xs: "90%", sm: "80%", md: "70%" },
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          align="center"
          sx={{ marginBottom: "24px" }}
        >
          Testimonials
        </Typography>

        {error && (
          <Typography variant="body1" color="error" sx={{ marginBottom: 2 }}>
            {error}
          </Typography>
        )}

        {data.length > 0 &&
          data.map((item, index) => (
            <React.Fragment key={index}>
              <Grid
                container
                alignItems="center"
                sx={{
                  padding: "16px 0px",
                 
                  marginBottom: "16px",
                  flexDirection: { xs: "column", sm: "row" },
                  textAlign: { xs: "left", sm: "inherit" },
                }}
              >
                {/* Image */}
                <Grid item xs={3} sm={2}>
                  <img
                    src={item.image}
                    alt="person"
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      border:'2px solid #62c3da',
                      objectFit: "cover",
                      margin: "0 auto",
                    }}
                  />
                </Grid>

                {/* Message */}
                <Grid item xs={12} sm={10}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ marginBottom: "8px" }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "gray" }}>
                    {item.message}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          ))}
      </Grid>
    </Grid>
  );
};

export default Testimonials;
