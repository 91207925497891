import React, {useEffect, useState, UseState} from 'react';
import { Grid, Typography, TextField, Divider,FormControlLabel, FormGroup, Checkbox,Card, CardContent, CardMedia, Button, Chip, Box  } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import ProductsData from './product.json';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { getProductsList } from '../apiCalls';

const Marketplace = () => {

    const categories = [
        {
          category: "Hardware Categories",
          items: ["CPU", "Monitors", "Printers", "RAM", "Routers", "Storage"]
        },
        {
          category: "Software Categories",
          items: [
            "Accounting",
            "Astrology",
            "Billing & Invoicing",
            "CRM",
            "Collaboration Software",
            "Design Solutions",
            "ERP",
            "Educational",
            "HR",
            "Malware Protection",
            "Petrol Pump",
            "Video Making"
          ]
        },
        {
          category: "Tags",
          items: ["Best Seller", "Newly Added", "Sponsored"]
        },
        {
          category: "Vendor",
          items: [
            "AWS",
            "Busy Infotech",
            "Hewlett Packard",
            "Quick Heal",
            "Tata Teleservices"
          ]
        }
      ];
    

    const [expandedCategories, setExpandedCategories] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});


        const handleResetFilters = () => {
        setSelectedFilters({});
        };


      const handleCategoryToggle = (category) => {
        setExpandedCategories((prev) =>
          prev.includes(category) ? prev.filter((cat) => cat !== category) : [...prev, category]
        );
      };
    
      // Handle checkbox changes
      const handleCheckboxChange = (category, item) => {
        setSelectedFilters((prev) => {
          const currentItems = prev[category] || [];
          const newItems = currentItems.includes(item)
            ? currentItems.filter((i) => i !== item)
            : [...currentItems, item];
    
          const updatedFilters = { ...prev, [category]: newItems };
    
          // Call the function with the updated selected categories
          console.log('Selected Filters:', updatedFilters);
          return updatedFilters;
        });
      };

      const handleProduvtView = (id) => {
        window.location.href = `/marketplace/product/${id}`
      }
      



      const [products, setProducts] = useState([]); 
      const [error, setError] = useState(''); 
      const [count , setCount ] = useState();
    
      const fetchTestimonialsWithHandling = async () => {
        try {
          const data = await getProductsList();
          if(data.success){
            const updatedData = data?.productList.map(product => ({
                ...product,
                isBestSeller: Math.random() < 0.5 // Randomly assign true or false
            }));
            setProducts(updatedData);
            setCount(updatedData.length)
          }else{
            toast.error(data.message)
          }
        } catch (err) {
          setError('Failed to load testimonials. Please try again later.');
          console.error('Error fetching testimonials:', err);
        }
      };
    
      useEffect(() => {
        fetchTestimonialsWithHandling();
      }, []);
    

    //   const mappedProducts = products?.length && products.map((product) => ({
    //     name: product.name.split("products/")[1],
    //     type: product.type,
    //     categories: product.categories,
    //     description: product.marketing.description,
    //     displayName: product.marketing.displayName,
    //     caption: product.marketing.caption,
    //     image: product.marketing.defaultImage.content,
    //   }));

    //   const updatedProducts = addBestSellerFlag(mappedProducts, 7);


    return(
        <>
            <Grid sx={{background:'#f8f8f8'}}>
                <Grid container >
                    <Grid 
                        sx={{
                            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(https://png.pngtree.com/thumb_back/fh260/background/20201028/pngtree-abstract-technology-background-technical-electric-image_443494.jpg)',
                            backgroundSize: 'cover', 
                            backgroundPosition: 'center', 
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                        }}
                    >
                        <Grid container justifyContent='flex-start'  direction='column' sx={{width:{xs:'90%',sm:'98%',md:'80%'},margin:'60px auto'}}>
                            <Grid item>
                                <Typography variant='h5' color='#fff' fontWeight='bold'>AppBowl Marketplace</Typography>
                            </Grid>
                            <Grid item pt={2}>
                                <Typography variant='subtitle1' color="#BABABA">
                                    Discover top-rated software solutions at unbeatable prices. Whether you're a professional, business owner, or tech enthusiast, AppBowl has the perfect tools for you.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction='column' sx={{width:{xs:'80%',sm:'80%',md:'90%'},margin:'30px auto'}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid container direction='row' justifyContent='space-between'>
                                    <Grid item>
                                        <Typography variant='h6' fontWeight='bold'>Marketplace</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='subtitle1'>{count} results</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                                <TextField
                                    id="outlined-basic"
                                    label="search products"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} pt={2} >
                        <Grid container justifyContent='space-between' direction='row'>
                            <Grid item md={2.6}>
                                <Grid container justifyContent='space-between' fontSize={'14px'}>
                                    <Grid item marginBottom={1}>
                                        <Typography variant='subtitle1' fontWeight='bold' >Filters</Typography>
                                    </Grid>     
                                    <Grid item>
                                        <Typography variant='subtitle2' pt={.4} sx={{cursor:'pointer'}} onClick={handleResetFilters}>RESET</Typography>
                                    </Grid>
                                    {categories.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Grid container justifyContent='space-between' pt={2}>
                                                <Grid item sx={{display:'flex',alignItems:'flex-end'}}>
                                                    <Typography variant='body2' fontWeight='bold'>{item.category}</Typography>
                                                </Grid>
                                                <Grid item sx={{display:'flex',alignItems:'flex-end', cursor:'pointer'}} onClick={() => handleCategoryToggle(item.category)}>
                                                    {expandedCategories.includes(item.category) ? <RemoveIcon /> : <AddIcon />}
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ borderWidth: '1.5px', borderColor: '#62c3da', width: '100%' , marginBottom: '12px'}} />
                                            {expandedCategories.includes(item.category) &&
                                            item.items.map((filter, idx) => (
                                                <Grid container >
                                                    <FormGroup key={idx}>
                                                        <FormControlLabel
                                                            sx={{ margin: 0,padding:0 }}
                                                            control={
                                                                <Checkbox
                                                                    
                                                                    sx={{
                                                                        color: '#62c3da',
                                                                        margin: 0,
                                                                        padding:'2px',
                                                                        '&.Mui-checked': {
                                                                            color: '#62c3da',
                                                                            margin: 0,padding:'2px' 
                                                                        },
                                                                        '& .MuiSvgIcon-root': {
                                                                            borderRadius: '4px'
                                                                        }
                                                                    }}
                                                                    checked={selectedFilters[item.category]?.includes(filter) || false}
                                                                    onChange={() => handleCheckboxChange(item.category, filter)}
                                                                />
                                                            }
                                                            label={
                                                                <Typography variant="caption">
                                                                    {filter}
                                                                </Typography>
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item md={9.2} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {products?.length ? (
                                    <Grid container spacing={2}>
                                        {products?.length && products.map((item , index)=> (
                                            <Grid item xs={12} sm={6} md={4} lg={3} key={index} >
                                                <Card sx={{ borderRadius: 2, boxShadow: 4 , padding: 'auto 20px', cursor:'pointer','&:hover .zoom-image': { transform: 'scale(1.3)' } }} onClick={()=>{handleProduvtView(item.product_name)}} >
                                                    {item.isBestSeller ? (
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', minHeight: '40px'}}>
                                                            <Chip label="BEST SELLER" color="primary" sx={{ backgroundColor: '#FFD700', color: '#000', fontWeight: 'bold', borderRadius: '4px 4px 4px 0' }} />
                                                        </Box>
                                                    ):(
                                                        <Grid sx={{minHeight:'40px'}}>

                                                        </Grid>
                                                    )}
                                                    <CardMedia
                                                        component="img"
                                                        image={item.marketing_defaultImage_content || "https://www.smartflowsheet.com/Images/smartflow/smartflow-logo.png" }
                                                        alt={item.product_name}
                                                        sx={{ objectFit: 'contain',width:'50%',margin:'0px auto',maxHeight:'40px', transition: 'transform 0.5s ease','&:hover': { transform: 'scale(1.3)' } }}
                                                        className="zoom-image"
                                                    />
                                                    <CardContent sx={{padding:'12px 8px',paddingBottom:'0px'}}>
                                                        <Typography gutterBottom variant="body1" component="div" fontWeight='bold' sx={{minHeight:'50px', fontSize: '14px', display: 'flex', alignItems: 'center'}}>
                                                            {item.marketing_displayName.length > 40 ? `${item.marketing_displayName.substring(0, 40)}...` : item.marketing_displayName}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {item.type}
                                                        </Typography>
                                                        <Grid sx={{minHeight:'50px !important',maxHeight:'50px !important' }} pt={.5}>
                                                            <Typography variant="caption" color="text.secondary" sx={{lineHeight:0}} >
                                                                {item.marketing_caption.length > 60 ? `${item.marketing_caption.substring(0, 60)}...` : item.marketing_caption}
                                                            </Typography>
                                                        </Grid>
                                                            <Grid container direction='row' justifyContent='space-between' alignItems='center' pt={.5} >
                                                                <Grid item>
                                                                    <Grid container direction='column'>
                                                                        <Grid item>
                                                                            <Typography variant='caption' sx={{ fontSize: '0.7rem' }}>Starting at </Typography>
                                                                        </Grid>
                                                                        <Grid item sx={{display:'flex',direction:'row', fontSize: '14px'}}>
                                                                            <Typography variant="body1" color="text.primary" fontWeight='bold' marginRight={"4px"}>
                                                                                INR
                                                                            </Typography>
                                                                            <Typography variant="body1" color="text.primary" fontWeight='bold'>
                                                                                {item.amount || "-"}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <KeyboardArrowRightIcon sx={{color:'#62c3da',border:'2px solid #62c3da',borderRadius:'50px'}} />
                                                                </Grid>
                                                            </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}

                                    </Grid>
                                ):(
                                    <Typography variant='h5'>No Products Found</Typography>
                                )}
              
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default Marketplace;