import React from "react";
import { Grid, Typography, Button } from "@mui/material";



// onClick={() => {window.location.href='/user/dashboard'}}
const OrderSucess = () => {

    const handleNav = () =>[
        window.location.href='/user/dashboard'
    ]

    return(
        <>
            <Grid>
                <Grid container spacing={1.8} justifyContent='center' alignItems='center' direction='column' sx={{background:'#f8f8f8',minHeight:'700px'}} >
                    <img src="https://cdni.iconscout.com/illustration/premium/thumb/order-confirmed-illustration-download-in-svg-png-gif-file-formats--shopping-online-confirmation-successful-handy-pack-delivery-illustrations-4273317.png" alt="cart-success" style={{width:'300px'}} />
                    <Grid item >
                        <Typography variant="h6" fontWeight='bold'>Order Received</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="body2" fontWeight='bold'>Order ID: 1265762</Typography>
                    </Grid>
                    {/* <Grid item >
                        <Typography variant="subtitle2" >Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
                    </Grid> */}
                    {/* <Grid item >
                        <Typography variant="subtitle2" >or</Typography>
                    </Grid> */}
                    <Grid item  sx={{maxWidth:'300px',minWidth:'300px'}}>
                        <Button sx={{border:'2px solid #1FE8E8',background:'#1FE8E8',borderRadius:'20px' , color:'black',fontWeight:'bold'}} fullWidth onClick={handleNav} >Dashboard</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export  default OrderSucess;