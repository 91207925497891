import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Divider,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem 
} from "@mui/material";
import Slide from '@mui/material/Slide';
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";

import { updateCustomer, getCustomerDetails } from "../../apiCalls";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Dashboard = () => {
  const [open, setOpen] = useState(false); // State for dialog visibility
  const [language, setLanguage] = useState("EN"); 
  const [userDetails , setUserDetails] = useState();
  const [isLoading , setIsLoading ] = useState(false)
  const [reRun , setReRun] = useState();
  const [signupFormData, setSignupFormData] = useState({
    organizationName: '',
    name: '',
    title: '',
    phone: '',
    email: '',
    country: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    gst: '',
  });

  const fetchOrder = async () => {
    try {
      const savedUser = JSON.parse(localStorage.getItem('appbowlUser'));
      if (savedUser) {
        setIsLoading(true)
        const data = await getCustomerDetails(savedUser?.customerId);

        if (data.success) {
          setIsLoading(false)
          const customer = data.data;

          setSignupFormData((prev) => ({
            ...prev,
            organizationName: customer.customerOrganization || '',
            name: customer.customerName || '',
            title: customer.customerTitle || '',
            phone: customer.customerPhone || '',
            email: customer.customerEmail || '',
            country: customer.customerAddress?.country || '',
            streetAddress: customer.customerAddress?.street || '',
            city: customer.customerAddress?.city || '',
            state: customer.customerAddress?.state || '',
            zipCode: customer.customerAddress?.zip || '',
            gst: customer.gst || '',
          }));
          setUserDetails(data.data)
        } else {
          setIsLoading(false)
          toast.error(data.message);
        }
      } else {
        toast.error("Please Login to Continue");
        setTimeout(() => {
          window.location.href = "/home";
        }, 2000);
      }
    } catch (err) {
      console.error('Error fetching orders:', err);
    }
  };


  useEffect(() => {
    fetchOrder();
  }, [reRun]);

  

  const [signupErrors, setSignupErrors] = useState({});

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()   
    
    const savedUser = JSON.parse(localStorage.getItem('appbowlUser'));

    if(savedUser){
      try {
        const dataToSend = {
          customerOrganization: signupFormData.organizationName,
          customerId: savedUser?.customerId,
          customerAddress: {
            street: signupFormData.streetAddress,
            city: signupFormData.city,
            state: signupFormData.state,
            zip: signupFormData.zipCode,
            country: "IN",
          },
          customerName: signupFormData.name,
          customerEmail: signupFormData.email,
          customerTitle: signupFormData.title,
          customerPhone: signupFormData.phone,
          gst: signupFormData.gst,
          languageCode: "EN",
        }
        const response = await updateCustomer(dataToSend)
        if (response.success) {
          setReRun(!reRun);
          fetchOrder();
          toast.success("profile updated successfully!");

        }else{
          toast.error("Something Went Wrong")
        }
      
      } catch (error) {
        console.error('Error:', error);
      }
      setOpen(false);
    }
  };



  const handleSignupChange = (e) => {
    const { name, value } = e.target;
    setSignupFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <>
      {isLoading ? (
        <Box
            sx={{
              flexGrow: 1,
              padding: "20px",
              backgroundColor: "#f4f6f8",
              minHeight: "100vh",
            }}
          >
            <Grid container justifyContent='center' alignItems="center" >
              <PropagateLoader
                color="#1FE8E8"  
                width={300}     
                height={4}     
              />
            </Grid>
        </Box>
      ):(
        <Box
          sx={{
            flexGrow: 1,
            padding: "20px",
            backgroundColor: "#f4f6f8",
            minHeight: "100vh",
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            {/* Profile Card */}
            <Grid item xs={12} sm={12} md={4}>
              <Card sx={{ padding: "20px" }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Avatar
                    alt="User Profile Picture"
                    src="https://static.vecteezy.com/system/resources/thumbnails/035/857/779/small/people-face-avatar-icon-cartoon-character-png.png"
                    sx={{ width: 120, height: 120, marginBottom: "15px" }}
                  />
                </Box>
                <Typography variant="h5" align="center" fontWeight="bold">
                  {userDetails?.customerName}
                </Typography>
                <Typography variant="body1" color="textSecondary" align="center">
                  {userDetails?.customerEmail}
                </Typography>
                <Typography variant="body1" color="textSecondary" align="center">
                  Username: {userDetails?.user.userName}
                </Typography>
                <Divider sx={{ marginY: "20px" }} />
                <Typography variant="body1" color="textSecondary" align="center">
                  +{userDetails?.customerPhone}
                </Typography>
                <Typography variant="body1" color="Highlight" align="center">
                  {`${userDetails?.customerAddress.city}, ${userDetails?.customerAddress.state}, ${userDetails?.customerAddress.country}`}
                </Typography>
                <Box mt={2} textAlign="center">
                  <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                    Edit
                  </Button>
                </Box>
              </Card>
            </Grid>
    
            {/* Details Section */}
            <Grid item xs={12} sm={12} md={8}>
              <Paper sx={{ padding: "20px" }}>
                <Typography variant="h6" gutterBottom>
                  Billing Address
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {`${userDetails?.customerAddress.street}, ${userDetails?.customerAddress.city}, ${userDetails?.customerAddress.state}, ${userDetails?.customerAddress.zip}, ${userDetails?.customerAddress.country}`}
                </Typography>
    
                <Divider sx={{ marginY: "20px" }} />
                <Typography variant="h6" gutterBottom>
                  Organization Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Organization Name:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {userDetails?.customerOrganization}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      GST:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {userDetails?.gst}
                    </Typography>
                  </Grid>
                </Grid>
    
                <Divider sx={{ marginY: "20px" }} />
                <Typography variant="h6" gutterBottom>
                  Microsoft Account Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Tenant ID:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {userDetails?.uid}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Microsoft Email Address:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {userDetails?.user.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog}
            PaperProps={{
              style: { width: '70vw', maxWidth: '95vw' }, // Adjust width for desktop and mobile
            }}
          >
            <DialogTitle>Edit Profile</DialogTitle>
            <DialogContent>
              <DialogContentText>Update your profile information below:</DialogContentText>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Name"
                      name="name"
                      variant="outlined"
                      value={signupFormData.name}
                      onChange={handleSignupChange}
                      error={!!signupErrors.name}
                      helperText={signupErrors.name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Organization Name"
                      name="organizationName"
                      variant="outlined"
                      value={signupFormData.organizationName}
                      onChange={handleSignupChange}
                      error={!!signupErrors.organizationName}
                      helperText={signupErrors.organizationName}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Title"
                      name="title"
                      variant="outlined"
                      value={signupFormData.title}
                      onChange={handleSignupChange}
                      error={!!signupErrors.title}
                      helperText={signupErrors.title}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Phone"
                      name="phone"
                      variant="outlined"
                      value={signupFormData.phone}
                      onChange={handleSignupChange}
                      error={!!signupErrors.phone}
                      helperText={signupErrors.phone}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Email"
                      name="email"
                      variant="outlined"
                      value={signupFormData.email}
                      onChange={handleSignupChange}
                      error={!!signupErrors.email}
                      helperText={signupErrors.email}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Street Address"
                      name="streetAddress"
                      variant="outlined"
                      value={signupFormData.streetAddress}
                      onChange={handleSignupChange}
                      error={!!signupErrors.streetAddress}
                      helperText={signupErrors.streetAddress}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="City"
                      name="city"
                      variant="outlined"
                      value={signupFormData.city}
                      onChange={handleSignupChange}
                      error={!!signupErrors.city}
                      helperText={signupErrors.city}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="State"
                      name="state"
                      variant="outlined"
                      value={signupFormData.state}
                      onChange={handleSignupChange}
                      error={!!signupErrors.state}
                      helperText={signupErrors.state}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Zip Code"
                      name="zipCode"
                      variant="outlined"
                      value={signupFormData.zipCode}
                      onChange={handleSignupChange}
                      error={!!signupErrors.zipCode}
                      helperText={signupErrors.zipCode}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Country"
                      name="country"
                      variant="outlined"
                      value="India"
                      disabled
                      error={!!signupErrors.country}
                      helperText={signupErrors.country}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="GST"
                      name="gst"
                      variant="outlined"
                      value={signupFormData.gst}
                      onChange={handleSignupChange}
                      error={!!signupErrors.gst}
                      helperText={signupErrors.gst}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={handleCloseDialog}>Cancel</Button>
              <Button type="submit" onClick={handleSubmit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>

  );
};

export default Dashboard;
