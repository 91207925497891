import React from 'react';
import { Grid } from '@mui/material';
import appbowlHome from '../appbowlHome.jpeg';

const Home1 = () => {
    return (
        <Grid container style={{ height: '100vh', overflow: 'hidden' }}>
            <img
                src={appbowlHome}
                alt="home"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
        </Grid>
    );
};

export default Home1;
