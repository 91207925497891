import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';  // Add social media icons

function Footer() {
  return (
    <Box 
      sx={{ 
        backgroundColor: 'black', 
        color: 'white', 
        padding: '40px 20px',
        marginTop: 'auto'
      }}
    >
      <Grid container spacing={4} justifyContent="space-between" sx={{ width: { xs: '98%', sm: '98%', md: '80%' }, margin: '0 auto' }}>
        
        <Grid item xs={12} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            AppBowl
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
              AppBowl simplifies your business needs with easy-to-use tools. Connect, manage, and grow your business with seamless functionality.
          </Typography>
        </Grid>
        
        {/* Quick Links */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Quick Links
          </Typography>
          <Link href="/" color="inherit" sx={{ display: 'block' }}>Home</Link>
          <Link href="/marketplace" color="inherit" sx={{ display: 'block' }}>Products</Link>
          <Link href="/about" color="inherit" sx={{ display: 'block' }}>About Us</Link>
          <Link href="/blogs" color="inherit" sx={{ display: 'block' }}>Blog</Link>
          <Link href="/privacyandpolicy" color="inherit" sx={{ display: 'block' }}>Privacy Policy</Link>
        </Grid>
        
        {/* Contact Info */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Contact Info
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Email: info@appbowl.com
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Phone: (123) 456-7890
          </Typography>
          <Typography variant="body1">
            Address: 123 Appbowl Street, City, Country
          </Typography>
        </Grid>
        
        {/* Social Media Links */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Follow Us
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, marginTop: '10px' }}>
            <Link href="https://www.facebook.com" target="_blank" color="inherit">
              <Facebook />
            </Link>
            <Link href="https://www.twitter.com" target="_blank" color="inherit">
              <Twitter />
            </Link>
            <Link href="https://www.instagram.com" target="_blank" color="inherit">
              <Instagram />
            </Link>
            <Link href="https://www.linkedin.com" target="_blank" color="inherit">
              <LinkedIn />
            </Link>
          </Box>
        </Grid>

      </Grid>

      {/* Footer Legal Information */}
      <Box sx={{ textAlign: 'center', marginTop: '30px', borderTop: '1px solid #444', paddingTop: '15px' }}>
        <Typography variant="body2" sx={{ marginBottom: '5px' }}>
          © {new Date().getFullYear()} AppBowl. All rights reserved.
        </Typography>
        <Typography variant="body2">
          <Link href="#" color="inherit" sx={{ marginRight: 2 }}>Terms & Conditions</Link>
          <Link href="#" color="inherit">Privacy Policy</Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
