import React, { useState, useMemo, useEffect } from "react";
import { Grid, TextField, Typography, Table, Button, Divider, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination, Paper } from "@mui/material";
import { getOrdersList, getOrderDetails, cancelOrder } from '../../apiCalls';
import { toast } from "react-toastify";
import PropagateLoader from "react-spinners/PropagateLoader";
import CreateIcon from '@mui/icons-material/Create';

const Orders = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('orderId');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDetails , setOrderDetails] = useState(null);
  const [orderId , setOrderId ] = useState(null);
  const [isLoading , setIsLoading ] = useState(false);



  useEffect(()=>{

    const fetchOrder = async () => {
      if(orderId){
          try {
            const savedUser = JSON.parse(localStorage.getItem('appbowlUser'));
  
            if (savedUser) {
                const data = await getOrderDetails(savedUser?.customerId , orderId );
                if (data.success) {
                  setOrderDetails(data?.data);
                } else {
                    toast.error(data.message);
                }
            } else {
                toast.error("Please Login to Continue");
                setTimeout(() => {
                    window.location.href = "/home";
                }, 2000);
            }
        } catch (err) {
            console.error('Error fetching orders:', err);
        }
      }else{
        return;
      }
    }

    fetchOrder();
  },[orderId])

  const getOrderList = async () => {
    try {
        const savedUser = JSON.parse(localStorage.getItem('appbowlUser'));

        if (savedUser) {
          setIsLoading(true)
            const data = await getOrdersList(savedUser?.customerId);
            if (data.success) {
              setIsLoading(false)
              const processedOrders = data.data.orders.flatMap(order => {
                  const orderId = order.name.split("/").pop(); // Extract orderId from 'name'
                  
                  return order.orderItems.map(item => ({
                      productName: item.catalogAttributes.find(attr => attr.name === "sku.DisplayName")?.value || "Unknown",
                      quantity: item.quantity,
                      price: (item.price * item.quantity).toFixed(2),
                      status: item.status,
                      createdAt: order.createTime,
                      orderId: orderId
                  }));
              });
          
              console.log(processedOrders, "Processed Orders");
              setData(processedOrders);
          } else {
              toast.error(data.message);
              setIsLoading(false)
          }              
        } else {
            toast.error("Please Login to Continue");
            setTimeout(() => {
                window.location.href = "/home";
            }, 2000);
        }
    } catch (err) {
        console.error('Error fetching orders:', err);
        setIsLoading(false)
    }
};


  useEffect(() => {
    getOrderList();
}, []);


  const handleOrderCancel = async () => {
    if(orderId){
      try {
        const savedUser = JSON.parse(localStorage.getItem('appbowlUser'));

        if (savedUser) {

          const payload = {
            customerId: savedUser?.customerId ,
            orderId: Number(orderId)
          }

              const data = await cancelOrder(payload);
              if (data.success) {
                  setOrderId(null);
                  setOrderDetails(null);
                  toast.success(data.message);
                  getOrderList()
              } else {
                  toast.error(data.message);
              }
          } else {
              toast.error("Please Login to Continue");
              setTimeout(() => {
                  window.location.href = "/home";
              }, 2000);
          }
      } catch (err) {
          console.error('Error fetching orders:', err);
      }
    }else{
      toast.error("Incorrect Order Id")
      return;
    }
  }


  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const filteredData = useMemo(() => 
    data?.filter(item =>
      item.productName?.toLowerCase().includes(search?.toLowerCase()) ||
      item.status?.toLowerCase().includes(search?.toLowerCase())
    ),
    [search, data]
  );

  const sortedData = useMemo(() => 
    filteredData?.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
      return 0;
    }),
    [filteredData, order, orderBy]
  );
  
useEffect(() => {
  console.log('sssssss',sortedData);
  
},[sortedData])



const handlePrint = (data) => {
  const printContent = `
    <div style="max-width: 800px; margin: 0 auto; border: 1px solid #000; padding: 30px;">
      <div style=" border: 1px solid gray;">
        <!-- Header Section -->
        <div style="display: flex; justify-content: space-between; align-items: start; margin-bottom: 20px;">
            <div style="display: flex; align-items: center;">
                <img src="/logo.png" alt="Microline" style="height: 50px; margin-left: 5px; margin-top: 5px;">
                <div>
                    <div style="font-size: 12px; margin-top: 5px;">Branch : Mumbai (HO)</div>
                </div>
            </div>
            <div style="text-align: right;">
                <h1 style="margin: 0; font-size: 24px;  margin-right: 5px;  margin-top: 5px;">Microline India Pvt. Ltd.
                </h1>
                <div style="font-size: 12px; margin-right: 10px; margin-top: 5px;">GST No : MUM</div>
            </div>
        </div>

        <h2 style="text-align: center; margin: 20px 0; color: #333;">PURCHASE ORDER</h2>

        <!-- Order Details -->
        <div
            style="display: flex; justify-content: space-between; margin-bottom: 20px; font-size: 14px; border-top: 1px solid gray;  border-bottom: 1px solid gray;">
            <div>
                <div style="margin-bottom: 10px; margin-top: 10px;  margin-left: 10px;">
                    <span style="font-weight: bold;">P.O. No : </span>
                    <span>ML/PO/23-24/3</span>
                </div>
            </div>
            <div>
                <div style="margin-bottom: 10px; margin-top: 10px;  margin-right: 10px;">
                    <span style="font-weight: bold;">P.O. Date : </span>
                    <span>07-Aug-2023</span>
                </div>
            </div>
        </div>

        <!-- Address Section -->
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px; font-size: 14px;">
            <div style="width: 45%; margin-left: 10px;">
                <div style="font-weight: bold; margin-bottom: 10px;">To :</div>
                <div style="font-weight: bold;">Senses Electronics Pvt. Ltd.</div>
                <div>M No. 818, At Post Bhugaon</div>
                <div>Tal Mulshi, Pune 412108 412108</div>
                <div style="margin-top: 10px;">
                    <span style="font-weight: bold;">GST No. : </span>
                    <span>27AAUCS0189B1Z4</span>
                </div>
            </div>
            <div style="width: 45%;">
                <div>
                    <div style="font-weight: bold; margin-bottom: 10px;">Delivery Address :</div>
                    <div>Microline India Pvt. Ltd. Eucharistic Congress Bldg</div>
                    <div>No. 2, 2nd Floor 5 Convent Street, Colaba, Mumbai,</div>
                    <div>Maharashtra 400001</div>
                </div>
                <div style="margin-top: 20px;">
                    <div style="font-weight: bold; margin-bottom: 10px;">Billing Address :</div>
                    <div>Microline India Pvt. Ltd. Eucharistic Congress Bldg</div>
                    <div>No. 2, 2nd Floor 5 Convent Street, Colaba, Mumbai,</div>
                    <div>Maharashtra 400001</div>
                </div>
            </div>
        </div>

        <!-- Table Section -->
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px; font-size: 14px;">
            <thead>
                <tr style="border-top: 1px solid gray; border-bottom: 1px solid gray;">
                    <th style="  padding: 8px; text-align: left;">Description</th>
                    <th style="  padding: 8px; text-align: left;">Customer</th>
                    <th style="  padding: 8px; text-align: left;">Warranty</th>
                    <th style="  padding: 8px; text-align: left;">HSN Code</th>
                    <th style="  padding: 8px; text-align: center;">Qty.</th>
                    <th style="  padding: 8px; text-align: right;">Rate</th>
                    <th style="  padding: 8px; text-align: right;">Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="  padding: 8px;">Senses Interactive Intelligent panel Premium 86"</td>
                    <td style="  padding: 8px;">St Xaviers High School</td>
                    <td style="  padding: 8px;">1 Year</td>
                    <td style="  padding: 8px;">84715000</td>
                    <td style="  padding: 8px; text-align: center;">1</td>
                    <td style="  padding: 8px; text-align: right;">0.00</td>
                    <td style="  padding: 8px; text-align: right;">0.00</td>
                </tr>
                <tr>
                    <td style="  padding: 8px;">Senses Interactive Intelligent panel Premium 65"</td>
                    <td style="  padding: 8px;">St Xaviers High School</td>
                    <td style="  padding: 8px;">1 Year</td>
                    <td style="  padding: 8px;">84715000</td>
                    <td style="  padding: 8px; text-align: center;">1</td>
                    <td style="  padding: 8px; text-align: right;">0.00</td>
                    <td style="  padding: 8px; text-align: right;">0.00</td>
                </tr>
                <tr>
                    <td style="  padding: 8px;">Senses Interactive Intelligent panel Premium 75"</td>
                    <td style="  padding: 8px;">St Xaviers High School</td>
                    <td style="  padding: 8px;">1 Year</td>
                    <td style="  padding: 8px;">84715000</td>
                    <td style="  padding: 8px; text-align: center;">1</td>
                    <td style="  padding: 8px; text-align: right;">0.00</td>
                    <td style="  padding: 8px; text-align: right;">0.00</td>
                </tr>
            </tbody>
        </table>

        <!-- Totals Section -->
        <div
            style="display: flex; justify-content: space-between; margin-bottom: 20px; border-top: 1px solid gray; font-size: 14px;">
            <div style="width: 50%; margin-left: 10px;">
                <div style="margin-bottom: 10px; margin-top: 10px;">
                    <span style="font-weight: bold;">Freight : </span>
                    <span>Extra</span>
                </div>
                <div style="margin-bottom: 10px;">
                    <span style="font-weight: bold;">Warranty : </span>
                    <span>3 year onsite warranty including Physical Damage from Manufacturer</span>
                </div>
                <div style="margin-bottom: 10px;">
                    <span style="font-weight: bold;">Payment Terms : </span>
                    <span>45 Days</span>
                </div>
                <div style="font-style: italic;">Rupee Zero Only</div>
            </div>
            <div style="width: 30%; margin-right: 10px;">
                <div style="display: flex; justify-content: space-between; margin-bottom: 5px; margin-top: 10px;">
                    <span>Total Amount :</span>
                    <span>0.00</span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                    <span>CGST @ 9 :</span>
                    <span>0.00</span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                    <span>SGST @ 9% :</span>
                    <span>0.00</span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                    <span>IGST @ 0% :</span>
                    <span>0.00</span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-bottom: 5px; font-weight: bold;">
                    <span>Grand Total :</span>
                    <span>0.00</span>
                </div>
            </div>
        </div>

        <!-- Signature Section -->
        <div style="display: flex; justify-content: space-between; margin-top: 40px; font-size: 14px;">
            <div style="text-align: center; margin-left: 10px;">
                <div>Accounts Team</div>
            </div>
            <div style="text-align: center;">
                <div>Purchase Team</div>
            </div>
            <div style="text-align: center; margin-right: 10px;">
                <div style="font-style: italic;">Authorized signatory</div>
            </div>
        </div>

        <!-- Footer -->
        <div style="margin-top: 40px; text-align: center; font-size: 12px; color: #666; border-top: 1px solid gray;">
            <div style="margin-bottom: 5px; margin-top: 10px;">Microline India Pvt. Ltd.</div>
            <div>Eucharistic Congress Bldg No. 2, 2nd Floor 5 Convent Street, Colaba, Mumbai, Maharashtra 400001</div>
        </div>
    </div>
    </div>
  `;

  // Open a new window for printing
  const printWindow = window.open("", "", "width=800,height=600");

  // Write the content and styles for printing
  printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
          @page {
            size: A4;
            margin: 10mm;
          }
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            font-size: 12px;
          }
        </style>
      </head>
      <body>
        ${printContent}
      </body>
    </html>
  `);

  // Close the document and trigger the print dialog
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();

  // Close the print window after printing
  printWindow.close();
};


  return (
    <>
        {orderDetails ? (
          // <Grid container spacing={2} padding={2}>
          //   <Grid item xs={12} mb={2}>
          //     <Typography variant="h6" fontWeight='bold'>Order Details</Typography>
          //   </Grid>
          // </Grid>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} mb={2}>
              <Typography variant="h6" fontWeight="bold">
                Order Details
              </Typography>
            </Grid>
      
            {/* General Order Information */}
            <Grid item xs={12} md={6}>
              <Typography variant="body1"><strong>Reference ID:</strong> {orderDetails.referenceId}</Typography>
              <Typography variant="body1"><strong>Display Name:</strong> {orderDetails.displayName}</Typography>
              <Typography variant="body1"><strong>User Name:</strong> {orderDetails.userName}</Typography>
              <Typography variant="body1"><strong>User Email:</strong> {orderDetails.userEmail}</Typography>
              <Typography variant="body1"><strong>Status:</strong> {orderDetails.status}</Typography>
              <Typography variant="body1"><strong>Currency Code:</strong> {orderDetails.currencyCode}</Typography>
              <Typography variant="body1"><strong>Total:</strong> {orderDetails.total}</Typography>
            </Grid>
      
            {/* Order Items */}
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold" marginTop={2}>
                Order Items
              </Typography>
              <Divider sx={{ marginBottom: 2 }} />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Reference ID</strong></TableCell>
                      <TableCell><strong>Product</strong></TableCell>
                      <TableCell><strong>Quantity</strong></TableCell>
                      <TableCell><strong>Provider</strong></TableCell>
                      <TableCell><strong>Price</strong></TableCell>
                      <TableCell><strong>Status</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetails.orderItems.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.referenceId}</TableCell>
                        <TableCell>{item.skuId}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.providerName}</TableCell>
                        <TableCell>{item.price}</TableCell>
                        <TableCell>{item.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
      
            {/* Provisioning Info */}
            <Grid item xs={12} mt={2}>
              <Typography variant="h6" fontWeight="bold">
                Provisioning Info
              </Typography>
              <Divider sx={{ marginBottom: 2 }} />
              <Typography variant="body1"><strong>Process ID:</strong> {orderDetails.provisioningInfo.processId}</Typography>
              <Typography variant="body1"><strong>Process Name:</strong> {orderDetails.provisioningInfo.processName}</Typography>
            </Grid>
      
            {/* Timestamps */}
            <Grid item xs={12} mt={2}>
              <Typography variant="h6" fontWeight="bold">
                Timestamps
              </Typography>
              <Divider sx={{ marginBottom: 2 }} />
              <Typography variant="body1"><strong>Created At:</strong> {orderDetails.createTime}</Typography>
              <Typography variant="body1"><strong>Updated At:</strong> {orderDetails.updateTime}</Typography>
              <Typography variant="body1"><strong>Scheduled At:</strong> {orderDetails.scheduledAt}</Typography>
            </Grid>
            <Grid item xs={12} mt={2} display="flex" justifyContent="space-between">
              <Button variant="contained" color="primary"   onClick={() => {
                  setOrderDetails(null);
                  setOrderId(null);
                }}>
                Back
              </Button>
              {orderDetails.status !== "CANCELED" && (
                <Button variant="contained" color="error" onClick={handleOrderCancel}>
                  Cancel Order
                </Button>
              )}
              {orderDetails.status !== "CANCELED" && (
                <Button variant="contained" color="secondary" onClick={handlePrint}>
                  Download Invoice
                </Button>
              )}
            </Grid>
          </Grid>
        ):(
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} mb={2}>
              <Typography variant="h6" fontWeight='bold'>List of Orders</Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                label="Search by Product Name or Purchased By"
                value={search}
                onChange={handleSearchChange}
                size='small'
              />
            </Grid>
            {!data?.length ? (
              <Grid item xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                {isLoading ? (
                  <PropagateLoader
                    color="#1FE8E8"  
                    width={300}     
                    height={4}     
                  />
                ):(
                  <Typography>No Orders Found</Typography>
                )}
              </Grid>
            ):(
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          <TableSortLabel
                            active={orderBy === 'orderId'}
                            direction={orderBy === 'orderId' ? order : 'asc'}
                            onClick={() => handleRequestSort('orderId')}
                          >
                            OrderId
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          <TableSortLabel
                            active={orderBy === 'productName'}
                            direction={orderBy === 'productName' ? order : 'asc'}
                            onClick={() => handleRequestSort('productName')}
                          >
                            productName
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          <TableSortLabel
                            active={orderBy === 'price'}
                            direction={orderBy === 'price' ? order : 'asc'}
                            onClick={() => handleRequestSort('price')}
                          >
                            Price
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          <TableSortLabel
                            active={orderBy === 'quantity'}
                            direction={orderBy === 'quantity' ? order : 'asc'}
                            onClick={() => handleRequestSort('quantity')}
                          >
                            Quantity
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          <TableSortLabel
                            active={orderBy === 'status'}
                            direction={orderBy === 'status' ? order : 'asc'}
                            onClick={() => handleRequestSort('status')}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          <TableSortLabel
                            active={orderBy === 'createdAt'}
                            direction={orderBy === 'createdAt' ? order : 'asc'}
                            onClick={() => handleRequestSort('createdAt')}
                          >
                            Created On
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.orderId}>
                          <TableCell>{row.orderId}</TableCell>
                          <TableCell>{row.productName}</TableCell>
                          <TableCell>{row.price}</TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{new Date(row.createdAt).toLocaleString()} ({row.Timezone})</TableCell>
                          <TableCell><CreateIcon sx={{cursor:'pointer'}} onClick={()=>setOrderId(row.orderId)} /></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Grid>
            )}
          </Grid>
        )}
    </>
  );
};

export default Orders;
