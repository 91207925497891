// NotFoundPage.js
import React from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Updated import

const NotFoundPage = () => {
  const navigate = useNavigate(); // Updated hook

  const handleGoHome = () => {
    navigate('/'); // Updated function
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        p: 3,
      }}
    >
      <Box
        component="img"
        src="https://static.vecteezy.com/system/resources/previews/024/217/744/non_2x/design-template-for-web-page-with-404-error-isometric-page-not-working-error-png.png" // Replace with your image path
        alt="404 Not Found"
        sx={{
          width: 300,
          height: 'auto',
          mb: 3,
        }}
      />
      <Typography variant="h4" gutterBottom>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" paragraph>
        It looks like the page you’re looking for doesn’t exist. 
        You might have followed a broken link or mistyped the URL.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
      >
        Go to Home
      </Button>
    </Container>
  );
};

export default NotFoundPage;
