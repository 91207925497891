import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Paper, Button, Box } from "@mui/material";
import { toast } from "react-toastify";
import { support } from "../apiCalls";

const ContactUs = () => {

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email:  "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem("appbowlUser"));
    if (savedUser && savedUser.email) {
      setFormData((prev) => ({
        ...prev,
        email: savedUser.email, 
      }));
    }
  }, []);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formData).some((field) => !field.trim())) {
      toast.warning("Please fill in all the fields.");
      return;
    }

    try {
      const response = await support(formData);
      if (response.success) {
        toast.success("Your message has been sent. We'll get back to you shortly!");
        setFormData({ name: "", phone: "", email: "", subject: "", message: "" });
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Unable to submit your query. Please try again later.");
    }
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Paper
        elevation={3}
        sx={{
          maxWidth: 700,
          margin: "auto",
          padding: 4,
          borderRadius: 3,
          boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h3"
          align="center"
          sx={{
            marginBottom: 3,
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Contact Us
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{
            marginBottom: 5,
            color: "#666",
          }}
        >
          Have questions or need assistance? Fill out the form below, and our team will get back to you shortly.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Name"
                name="name"
                variant="outlined"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "#666" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                name="phone"
                variant="outlined"
                fullWidth
                value={formData.phone}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "#666" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email Address"
                name="email"
                variant="outlined"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "#666" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Subject"
                name="subject"
                variant="outlined"
                fullWidth
                value={formData.subject}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "#666" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Your Message"
                name="message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "#666" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  padding: 1.5,
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  borderRadius: 2,
                  backgroundColor: "#007BFF",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default ContactUs;
