import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import PropagateLoader from "react-spinners/PropagateLoader";
import { getSupportQueries } from "../../apiCalls";

const Support = () => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQueries = async () => {
      const savedUser = JSON.parse(localStorage.getItem("appbowlUser"));
      if (!savedUser) {
        toast.error("Please Login to Continue");
        setTimeout(() => (window.location.href = "/home"), 2000);
        return;
      }

      try {
        const response = await getSupportQueries(savedUser?.email);
        if (response.success) {
          setQueries(response.data);
        } else {
          toast.error("Failed to load queries.");
        }
      } catch (error) {
        console.error("Error fetching queries:", error);
        toast.error("An error occurred while fetching queries.");
      } finally {
        setLoading(false);
      }
    };

    fetchQueries();
  }, []);

  return (
    <Paper
      sx={{
        padding: 4,
        maxWidth: "90%",
        margin: "auto",
        mt: 4,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h5"
        align="center"
        sx={{ marginBottom: "2rem", fontWeight: "bold" }}
      >
        Your Support Queries
      </Typography>

      {loading ? (
        <Grid container justifyContent="center" sx={{ mt: 4 }}>
          <PropagateLoader
            color="#1FE8E8"  
            width={300}     
            height={4}     
          />
        </Grid>
      ) : queries.length > 0 ? (
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {["#", "Name", "Phone", "Email", "Subject", "Message"].map(
                  (header, index) => (
                    <TableCell key={index} align={header === "#" ? "center" : "left"}>
                      <strong>{header}</strong>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {queries.map((query, index) => (
                <TableRow key={query.id || index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>{query.name}</TableCell>
                  <TableCell>{query.phone}</TableCell>
                  <TableCell>{query.email}</TableCell>
                  <TableCell>{query.subject}</TableCell>
                  <TableCell>
                    <Tooltip title={query.message} arrow>
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 200, // Adjust as needed
                        }}
                      >
                        {query.message}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography align="center" sx={{ mt: 3, color: "gray" }}>
          No queries found.
        </Typography>
      )}
    </Paper>
  );
};

export default Support;
