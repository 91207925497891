import React from 'react';
import { useParams } from 'react-router-dom';

const BlogDetail = () => {
  const { id } = useParams();

  // Example blog data
  const blogPost = {
    id: 1,
    title: "Understanding React Hooks",
    author: {
      name: "John Doe",
      profilePic: "https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D",
      bio: "A software engineer and tech writer with a passion for front-end development.",
    },
    createdDate: "November 10, 2023",
    bannerImage: "https://www.shutterstock.com/image-photo/website-banner-laptop-female-typing-260nw-1176612745.jpg",
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
      "React Hooks allow you to use state and other React features without writing a class.",
      "The most commonly used Hooks are useState and useEffect, which help manage state and side effects.",
      "Using hooks, you can simplify component code, making it more readable and maintainable.",
      "In this post, we'll dive deep into each hook, exploring its use cases and best practices.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
      "Hooks are essential for managing complex states in functional components. They make code more predictable and manageable.",
      "React has several other hooks like useContext, useReducer, and useRef that further enhance functionality.",
      "Implementing custom hooks is also a great way to abstract logic and reuse it across multiple components."
    ]
  };

  return (
    <div style={{ maxWidth: '80%', margin: '0 auto', padding: '20px' }}>
      {/* Banner Image */}
      <img src={blogPost.bannerImage} alt="Blog Banner" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />

      {/* Blog Title and Meta Information */}
      <h1 style={{ fontSize: '2.5em', margin: '20px 0' }}>{blogPost.title}</h1>

      {/* Author and Date */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        {/* Author's Profile Picture */}
        <img
          src={blogPost.author.profilePic}
          alt={`${blogPost.author.name} Profile`}
          style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
        />
        <div>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{blogPost.author.name}</p>
          <p style={{ margin: 0, color: '#666' }}>{blogPost.author.bio}</p>
        </div>
        <p style={{ marginLeft: 'auto', fontStyle: 'italic', color: '#888' }}>{blogPost.createdDate}</p>
      </div>

      {/* Blog Content */}
      <div>
        {blogPost.content.map((paragraph, index) => (
          <p key={index} style={{ fontSize: '1.1em', lineHeight: '1.6em', margin: '15px 0' }}>
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

export default BlogDetail;
