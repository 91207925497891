import React from "react";
import { Grid, Typography } from "@mui/material";

const Subscription = () => {
  return (
    <>
      <Grid container justifyContent="center" alignItems='center'>
        <Typography>No Records Found</Typography>
      </Grid>
    </>
  );
};

export default Subscription;
