// ConfigComponent.js
import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { getTenent, getDomain } from "../../apiCalls";
function ConfigComponent({ isOpen, toggleDrawer }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOption, setSelectedOption] = useState('existing');
  const [billingDate, setBillingDate] = useState(new Date());
  const [autoRenew, setAutoRenew] = useState(true);
  const [tenantId, setTenantId] = useState('');
  const [domain, setDomain] = useState('');
  const [tenantInfo, setTenantInfo] = useState({ name: null, apiRun: false });

  console.log(tenantInfo ,"lllllllllllllllllll")

  const handleTenent = async () => {
    setTenantInfo({ name: null, apiRun: true }); // Indicate API call has started
  
    try {
      console.log('Tenant ID:', tenantId);
  
      const data = await getTenent(tenantId);
  
      // const data = await response.json();
  
      if (data.success && data?.response?.displayName) {
        console.log('API Success:', data);
        setTenantInfo({ name: data.response.displayName, apiRun: true }); 

        
      } else {
        console.log('API Failure:', data);
        setTenantInfo({ name: null, apiRun: true }); // Reset name on failure
      }
    } catch (error) {
      console.error('Error during API call:', error);
      setTenantInfo({ name: null, apiRun: true }); // Reset name on error
    }
  };
  

  const handleDomain = async () => {
    try {
      console.log('domain', domain);

      const response = await getDomain(domain)

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('API Response:', data);

    } catch (error) {
      console.error('Error making API call:', error);
    }


  }
  const handleTenentChange = (event) => {
    setTenantId(event.target.value);
  };
  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
      <Box sx={{ width: { xs: '100vw', sm: '100vw', md: '50vw' }, padding: '10px 20px' }}>
        <Typography variant="h6" fontWeight="bold" gutterBottom >
          Configuration
        </Typography>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTabs-indicator': {
              backgroundColor: '#1976d2', // Customize the indicator color
            },
          }}
        >
          <Tab
            label="New Tenant Details"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              color: 'black',
              '&.Mui-selected': {
                color: '#1976d2', // Customize the color when selected
              },
            }}
          />
          <Tab
            label="Order Details"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              color: 'black',
              '&.Mui-selected': {
                color: '#1976d2', // Customize the color when selected
              },
            }}
          />
        </Tabs>
        {selectedTab === 0 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Microsoft Account Configuration
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Company Name: <strong>john_company</strong>
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Please enter the Tenant ID if you already have a Microsoft account, else create a new account.
            </Typography>

            <RadioGroup
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              row
              sx={{ mb: 2 }}
            >
              <FormControlLabel
                value="existing"
                control={<Radio />}
                label="Link Existing Microsoft Account"
              />
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="Create New Microsoft Account"
              />
            </RadioGroup>

            {selectedOption === 'existing' ? (
              <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mt: 2,
                  p: 2,
                  border: '1px solid #e0e0e0',
                  borderRadius: 2,
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <TextField
                  label="Tenant Id"
                  name="tenentId"
                  variant="outlined"
                  size="small" // Set size to small
                  fullWidth
                  value={tenantId}
                  onChange={handleTenentChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    minWidth: '300px',
                    fontSize: '0.875rem', // Smaller font size
                    textTransform: 'none',
                    borderRadius: 2,
                    px: 2, // Adjust padding for a smaller button
                  }}
                  onClick={handleTenent}
                >
                  Link Tenant
                </Button>
              </Box>
                {tenantInfo.apiRun && tenantInfo.name !== null && (
                  <Typography sx={{ mt: 2 }}>Hi {tenantInfo.name}</Typography>
                )}
                {tenantInfo.apiRun && tenantInfo.name === null && tenantId && (
                  <Typography sx={{ mt: 2, color: 'red' }}>Incorrect Tenant</Typography>
                )}
              </>
            ) : (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Contact Details
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField label="First Name *" fullWidth />
                  <TextField label="Middle Name" fullWidth />
                  <TextField label="Last Name *" fullWidth />
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField label="Contact Number *" fullWidth />
                  <TextField label="Email ID *" fullWidth defaultValue="workofdhanu@gmail.com" />
                  <FormControl fullWidth>
                    <InputLabel>Country or Region *</InputLabel>
                    <Select defaultValue="India">
                      <MenuItem value="India">India</MenuItem>
                      {/* Add more options as needed */}
                    </Select>
                  </FormControl>
                </Box>
                <Typography variant="subtitle1" gutterBottom>
                  Address Details
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField label="Address 1 *" fullWidth />
                  <TextField label="Address 2" fullWidth />
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField label="City *" fullWidth />
                  <FormControl fullWidth>
                    <InputLabel>State *</InputLabel>
                    <Select>
                      <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                      {/* Add more states as needed */}
                    </Select>
                  </FormControl>
                  <TextField label="Pin *" fullWidth />
                </Box>
                <Button variant="contained" color="primary" sx={{ mb: 2 }}>
                  Verify Address
                </Button>
                <Typography variant="subtitle1" gutterBottom>
                  Custom Domain
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TextField label="yourbusiness" variant="outlined" onChange={handleDomainChange} />
                  <Typography sx={{ mx: 1 }}>.onmicrosoft.com</Typography>
                  <Button variant="contained" onClick={handleDomain}>Check Availability</Button>
                </Box>
                <TextField label="Company Registration ID *" fullWidth sx={{ mb: 2 }} />
                <Typography variant="body2" color="textSecondary">
                  Based on the location you entered, Microsoft requires a company registration ID.
                  Please enter this information and click Continue to proceed.
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {selectedTab === 1 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Microsoft Customer Agreement
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Provide the details of the individual at the customer's organization attesting to the Microsoft Customer Agreement.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField label="First Name *" fullWidth />
              <TextField label="Last Name *" fullWidth />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Signed On *"
                  value={billingDate}
                  onChange={(newValue) => setBillingDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField label="Contact Number *" fullWidth />
              <TextField label="Email *" fullWidth defaultValue="workofdhanu@gmail.com" />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField label="End Customer PO Number" fullWidth />
              <TextField label="Reseller PO Number" fullWidth />
            </Box>
            <Typography variant="h6" gutterBottom>
              Auto-Renewal Settings
            </Typography>
            <FormControlLabel
              control={<Switch checked={autoRenew} onChange={(e) => setAutoRenew(e.target.checked)} />}
              label={`Enable Auto-Renew: ${autoRenew ? 'ON' : 'OFF'}`}
              sx={{ mb: 2 }}
            />
            <Typography variant="h6" gutterBottom>
              Billing Date Alignment
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Customize your charge cycles and ensure that new or renewed subscriptions align with your business needs.
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <RadioGroup defaultValue="purchaseDate" row>
                <FormControlLabel
                  value="purchaseDate"
                  control={<Radio />}
                  label="Align with purchase date"
                />
                <FormControlLabel
                  value="calendarDate"
                  control={<Radio />}
                  label="Align with calendar date"
                />
              </RadioGroup>
              <TextField
                label=""
                value={billingDate.toLocaleDateString()}
                InputProps={{ readOnly: true }}
              />
            </Box>
            <Typography variant="body2" color="textSecondary">
              You are aligning your new subscription billing date to {billingDate.toLocaleDateString()} and will prorate it for the remainder of the billing cycle. Please confirm to proceed.
            </Typography>
            <RadioGroup row sx={{ mt: 1 }}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button variant="contained" color="primary">
            Continue
          </Button>
          <Button variant="outlined" onClick={toggleDrawer(false)}>
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default ConfigComponent;
