import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, Typography, Paper, Checkbox, Button, FormControlLabel, FormGroup, Divider } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { toast } from 'react-toastify';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getProductDetail } from '../../apiCalls';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/react";
import Auth from '../auth/Auth';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { addToCart, deleteCartItem } from "../../apiCalls";

const addons = [
  { name: '1-Year Extended Warranty', term: 'Unlimited', frequency: 'Monthly', price: 'INR 50' },
  { name: '3-Year Extended Warranty', term: 'Unlimited', frequency: 'Monthly', price: 'INR 100' },
  { name: '5-Year Extended Warranty', term: 'Unlimited', frequency: 'Monthly', price: 'INR 150' },
];

const MarketplacePage = () => {

  // localStorage.removeItem('selectedPlans');


  const { id } = useParams();

  // const id = "SharePoint-in";

  const [productDetails , setProductDetail] = useState();
  const [error, setError] = useState(''); 
  const [allPlans , setAllPlans ] = useState([]);

  useEffect(()=>{
    const plans = productDetails?.definition_skus?.flatMap(skusItems => skusItems.plans) || [];

    

    setAllPlans(plans);
  },[productDetails?.definition_skus])

  const [visibleRows, setVisibleRows] = useState(10);

  const isMoreAvailable = visibleRows < allPlans.length;

  const loadMoreRows = () => {
    setVisibleRows(prevVisibleRows => prevVisibleRows + 10);
  };

  const [openAuth, setOpenAuth] = useState(false);

  const [selectedMpnIds, setSelectedMpnIds] = useState('');
  const [selectedPlans, setSelectedPlans] = useState([]);

  const handleAuthOpen = () => {
    setOpenAuth(true);
  };

  const handleAuthClose = () => {
    setOpenAuth(false);
  };
  const handleCheckboxChange = (mpnId) => (event) => {
    const { checked } = event.target;

    console.log(mpnId , event.target , "mmmmmmmmmmmmmmmmmmmmmmmmmmm")
    setSelectedMpnIds((prev) => {
      const idsArray = prev ? prev.split(',') : [];
      if (checked) {
        // Add mpnId if checked and not already present
        return [...idsArray, mpnId].join(',');
      } else {
        // Remove mpnId if unchecked
        return idsArray.filter((id) => id !== mpnId).join(',');
      }
    });
  };

  useEffect(() => {
    console.log('TTT ', selectedPlans);
    
  },[selectedPlans])

  // useEffect(() => {
  //   if (selectedMpnIds) {
  //     // Split the comma-separated string into an array of IDs
  //     const selectedIdsArray = selectedMpnIds.split(',');
      
  //     // Filter allPlans and add a default quantity field
  //     const filteredPlans = allPlans
  //       .filter((plan) => selectedIdsArray.includes(plan.mpnId))
  //       .map((plan) => ({
  //         ...plan,
  //         quantity: 1,
  //         cartPrice: plan?.appbowlPrice,
  //       }));
      
  //     setSelectedPlans(filteredPlans);
  //   } else {
  //     setSelectedPlans([]); // Clear selectedPlans if no IDs are selected
  //   }
  // }, [allPlans, selectedMpnIds]);
  

  useEffect(() => {
    const plans = productDetails?.definition_skus?.flatMap(skusItems => skusItems.plans) || [];
  
    setAllPlans(plans);
  }, [productDetails?.definition_skus]);
  
  useEffect(() => {
    if (selectedMpnIds) {
      // Split the comma-separated string into an array of IDs
      const selectedIdsArray = selectedMpnIds.split(',');
      
      // Filter allPlans and add a default quantity field, cartPrice, and skuId
      const filteredPlans = allPlans
        .filter((plan) => selectedIdsArray.includes(plan.mpnId))
        .map((plan) => {
          const sku = productDetails?.definition_skus?.find(skuItem =>
            skuItem.plans.some(p => p.mpnId === plan.mpnId) // Find the sku that contains the plan
          );
          
          return {
            ...plan,
            quantity: 1,
            cartPrice: plan?.appbowlPrice || plan?.pricebookMasterPrice,
            skuId: sku?.id, // Add skuId from the corresponding definition_skus
          };
        });
  
      setSelectedPlans(filteredPlans);
    } else {
      setSelectedPlans([]); // Clear selectedPlans if no IDs are selected
    }
  }, [allPlans, selectedMpnIds, productDetails?.definition_skus]);

  
  useEffect( () => {
    const getProduct = async () => {
      try {
        const data = await getProductDetail(id);
        if(data.success){
          if(data?.productList){
            setProductDetail(data?.productList);
          }
        }
      } catch (err) {
        setError('Failed to load testimonials. Please try again later.');
        console.error('Error fetching testimonials:', err);
      }
    }

    getProduct()
  }, [id]);

  // const handleQuantityChange = (index, action) => {
  //   setSelectedPlans((prevPlans) => {
  //     return prevPlans.map((plan, i) => {
  //       if (i === index) {
  //         const newQuantity = action === 'increase' ? plan.quantity + 1 : plan.quantity - 1;
  //         return {
  //           ...plan,
  //           quantity: newQuantity > 0 ? newQuantity : 1,
  //           cartPrice: plan.pricebookMasterPrice * (action === 'increase' ? plan.quantity + 1 : plan.quantity - 1),
  //         };
  //       }
  //       return plan;
  //     });
  //   });
  // };

  // const handleQuantityChange = (index, action) => {
  //   setSelectedPlans((prevPlans) => {
  //     return prevPlans.map((plan, i) => {
  //       if (i === index) {
  //         const newQuantity = Math.max(1, action === 'increase' ? plan.quantity + 1 : plan.quantity - 1);
  //         return {
  //           ...plan,
  //           quantity: newQuantity, // Ensure quantity stays at least 1
  //           cartPrice: plan.pricebookMasterPrice * newQuantity, // Update cartPrice based on the new quantity
  //         };
  //       }
  //       return plan;
  //     });
  //   });
  // };
  
  // const handleQuantityChange = (index, action) => {
  //   setSelectedPlans((prevPlans) => {
  //     return prevPlans
  //       .map((plan, i) => {
  //         if (i === index) {
  //           if (action === 'decrease' && plan.quantity === 1) {
  //             return null; 
              
  //           }
  //           const newQuantity = action === 'increase' ? plan.quantity + 1 : plan.quantity - 1;
  //           return {
  //             ...plan,
  //             quantity: newQuantity > 0 ? newQuantity : 1, // Ensure quantity is at least 1
  //             cartPrice: plan.pricebookMasterPrice * (action === 'increase' ? plan.quantity + 1 : plan.quantity - 1),
  //           };
  //         }
  //         return plan;
  //       })
  //       .filter(Boolean); // Remove null values
  //   });
  // };


  const habdleRemovefromCart = async () => {

    const savedUser = JSON.parse(localStorage.getItem('appbowlUser'));
    const productId = window.location.pathname.split('/').pop()


    try {
      const dataToSend = {
        productId : productId, 
        userId : savedUser?.id
      }
      const response = await deleteCartItem(dataToSend)
      if (response.success) {
        toast.success("cart item deleted successfully!");
      }else{
        toast.error("Something Went Wrong")
      }
    
    } catch (error) {
      console.error('Error:', error);
    }
  }

  
  const handleQuantityChange = (index, action) => {
    setSelectedPlans((prevPlans) => {
      return prevPlans.map((plan, i) => {
        if (i === index) {
          if (action === 'decrease' && plan.quantity === 1) {
            // habdleRemovefromCart()
            setSelectedMpnIds((prevMpnIds) => {
              const mpnIdsArray = prevMpnIds.split(','); // Convert to array
              const updatedMpnIdsArray = mpnIdsArray.filter((id) => id !== plan.mpnId); // Remove mpnId
              return updatedMpnIdsArray.join(','); // Convert back to string
            });
  
            const { mpnId, ...rest } = plan; // Remove mpnId from the plan object
            return rest;
          }
  
          const newQuantity = action === 'increase' ? plan.quantity + 1 : Math.max(1, plan.quantity - 1);
          return {
            ...plan,
            quantity: newQuantity,
            cartPrice: plan.appbowlPrice * newQuantity || plan.pricebookMasterPrice * newQuantity,
          };
        }
        return plan;
      });
    });
  };
  
  const [isEnable , setIsEnable ] =useState();

  const handleAddtoCart = async () => {
    const savedUser = JSON.parse(localStorage.getItem('appbowlUser'));
    if (savedUser) {
      if (selectedPlans.length > 0) {
        // localStorage.setItem('selectedPlans', JSON.stringify(selectedPlans));
        const productId = window.location.pathname.split('/').pop()
        try {
          const dataToSend = {
            productId: productId,
            userId: savedUser.id,
            cartItems: selectedPlans
          }
          const response = await addToCart(dataToSend)

          if (response.success) {
            toast.success("Added to Cart");
            setIsEnable(true);
          }else{
            toast.error(response?.message)
          }
          
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }else{
      handleAuthOpen();
    }
  }

  const handleCartNav = () => {
    window.location.href = '/user/cart';
  }

  const loaderStyles = css`
  display: block;
  margin: 0 auto; /* centers the loader horizontally */
`;
  return (
    <Grid>
    <Auth openAuth={openAuth} handleAuthClose={handleAuthClose} />
    {
      !productDetails ? 
      <div style={{
    display: 'flex',
    justifyContent: 'center',  
    alignItems: 'center',      
    height: '100vh',           
    backgroundColor: '#f8f8f8',
  }}>
      <PropagateLoader
        css={loaderStyles}
        color="#1FE8E8"  
        width={300}     
        height={4}     
      />
    </div>
       : 
    
      <Grid container sx={{background:'#f8f8f8'}} >
        <Grid 
            sx={{
                backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url(https://png.pngtree.com/thumb_back/fh260/background/20201028/pngtree-abstract-technology-background-technical-electric-image_443494.jpg)',
                backgroundSize: 'cover', 
                backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat',
                width: '100%'
            }}
        >
            <Grid container justifyContent='space-between'  direction='row' sx={{width:{xs:'90%',sm:'98%',md:'80%'},margin:'60px auto'}}>
                <Grid item md={1.4} sx={{display:'flex',alignItems:'center'}}>
                  <img src={productDetails.marketing_defaultImage_content} alt="logo" style={{width:'100%', maxWidth:'150px'}} />
                </Grid>
                <Grid item md={10.2}>
                    <Grid container direction='column' spacing={1}>
                      <Grid item>
                        <Typography variant='h6' color="#fff" fontWeight='bold'>
                          {productDetails.marketing_displayName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle1' color="#BABABA">
                            {productDetails.marketing_description}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" spacing={2} marginTop={1}>
                          <Grid item >
                            <Button sx={{background:'#ffd83f', color:'black',fontWeight:'bold',borderRadius:'20px',fontSize:"14px", padding: '6px 24px', '&:hover': {background: '#ffd83f'}}}>Hewellet Packard</Button>
                          </Grid>
                          <Grid item>
                            <Button sx={{background:'#ffd83f', color:'black',fontWeight:'bold',borderRadius:'20px',fontSize:"14px", padding: '6px 24px', '&:hover': {background: '#ffd83f'}}}>Routers</Button>
                          </Grid>
                          <Grid item>
                            <Button sx={{background:'#ffd83f', color:'black',fontWeight:'bold',borderRadius:'20px',fontSize:"14px", padding: '6px 24px', '&:hover': {background: '#ffd83f'}}}>Best Sellers</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid sx={{width:{xs:'98%',sm:'98%',md:'85%'},margin:'20px auto 10vh auto'}}>
          <Grid container justifyContent='space-between' direction='row' sx={{width:'100%'}}>
            <Grid item xs={12} md={3} sx={{display:{xs:'block',sm:'block',md:'none'}}} >
              <Grid sx={{padding:'10px 0'}}>
                <Typography variant='h6' fontWeight='bold' fontSize={'22px'}>
                  Summary
                </Typography>
              </Grid>
              <Grid>
                <Grid container direction='column' spacing={1} sx={{background:'#fff',padding:'20px 20px 0px'}}>
                    <Grid item xs={12} sm={12} md={12} sx={{margin:'0 0 12px 0'}}>
                      <Typography variant='body1' fontWeight='bold' fontSize={'14px'}>{productDetails.marketing_displayName}</Typography>
                    </Grid>
                    {/* {selectedPlans?.length ? selectedPlans.map((item , index)=>(
                      <Grid container direction='column' spacing={1} key={item.id}>

                        <Grid item xs={12} sm={12} md={12} pt={1}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item>
                              <Typography variant='body2'>Term:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>{item?.phases[0]?.type}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item>
                              <Typography variant='body2'>Frequency:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>{item?.billingPeriod}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item>
                              <Typography variant='body2'>Price:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>INR {item?.pricebookMasterPrice.toFixed(2)}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item>
                              <Typography variant='body2'>Quantity:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>1</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{margin:'14px 0'}}>
                          <Divider sx={{ borderWidth: '1px', borderColor: 'grey', width: '100%' }} />
                        </Grid>
                      </Grid>

                    )):(
                      <></>
                    )} */}
                    {selectedPlans?.length ? selectedPlans.map((item, index) => (
                        <Grid container direction='column' spacing={1} key={item.id}>
                          <Grid item xs={12} sm={12} md={12} pt={1}>
                            <Grid container direction='row' justifyContent='space-between'>
                              <Grid item>
                                <Typography variant='body2'>Term:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>{item?.phases[0]?.type}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container direction='row' justifyContent='space-between'>
                              <Grid item>
                                <Typography variant='body2'>Frequency:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>{item?.billingPeriod}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container direction='row' justifyContent='space-between'>
                              <Grid item>
                                <Typography variant='body2'>Price:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>INR {(item?.cartPrice || 0).toFixed(2)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                              <Grid item>
                                <Typography variant='body2'>Quantity:</Typography>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems='center' spacing={1}>
                                  <Grid item>
                                    <Grid
                                      sx={{
                                        border: '2px solid #1FE8E8',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding:'2px',
                                        cursor: 'pointer', // Optional: Add a pointer cursor for better UX
                                      }}
                                      onClick={() => handleQuantityChange(index, 'decrease')}
                                    >
                                      <RemoveIcon sx={{ fontSize: '18px'}} /> {/* Thicker icon */}
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant='body2' fontWeight="bold">{item.quantity}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Grid
                                      sx={{
                                        border: '2px solid #1FE8E8',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding:'2px',
                                        cursor: 'pointer', // Optional: Add a pointer cursor for better UX
                                      }}
                                      onClick={() => handleQuantityChange(index, 'increase')}
                                    >
                                      <AddIcon sx={{ fontSize: '18px'}} /> {/* Thicker icon */}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} sx={{ margin: '14px 0' }}>
                            <Divider sx={{ borderWidth: '1px', borderColor: 'grey', width: '100%' }} />
                          </Grid>
                        </Grid>
                      )) : (
                        <Typography variant='body2' textAlign='center'>No plans selected.</Typography>
                      )}

                    {/* <Grid item xs={12} sm={12} md={12} sx={{margin:'14px 0'}}>
                      <Divider sx={{ borderWidth: '1px', borderColor: 'grey', width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{margin:'0 0 12px 0'}} >
                      <Typography variant='body1' fontWeight='bold' fontSize={'14px'}>1-year Extended Warrenty</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} pt={1} >
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='body2'>Term:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>Unlimited</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='body2'>Frequency:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>Monthly</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='body2'>Price:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>INR 100</Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}













                    {/* <Grid item xs={12} sm={12} md={12}>
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='body2'>Quantity:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>Unlimited</Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={12} mt={6} >
                      <Button sx={{border:'2px solid #FFD83F',borderRadius:'20px' , color:'black',fontWeight:'bold', fontSize: '14px'}} fullWidth>Compare</Button>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} pb={2}>
                      <Button 
                        sx={{border:'2px solid #1FE8E8',
                          background:'#1FE8E8',borderRadius:'20px' , 
                          color:'black',fontWeight:'bold',marginTop: "6px", fontSize: '14px'}} 
                          fullWidth
                          disabled={!selectedPlans.length}
                          onClick={handleAddtoCart}
                        >
                          Add to Cart
                      </Button>
                    </Grid>
                    {isEnable && (
                    <Grid item xs={12} sm={12} md={12} pb={2}>
                      <Button 
                        sx={{border:'2px solid #1FE8E8',
                          background:'#1FE8E8',borderRadius:'20px' , 
                          color:'black',fontWeight:'bold',marginTop: "6px", fontSize: '14px'}} 
                          fullWidth
                          onClick={handleCartNav}
                        >
                          Go to Cart
                      </Button>
                    </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8.8} sx={{width:'100%'}}>
              <Grid sx={{padding:'10px 0', width:'100%'}}>
                <Typography fontWeight='bold' fontSize={'22px'}>
                  Explore
                </Typography>
              </Grid>
              <Grid sx={{width:'100%'}}>
                <Grid container direction='column' spacing={0.5} sx={{background:'#fff',padding:{xs:'none',sm:'none',md:'20px 20px 0px'}, width:'100%',margin:0}}>
                  <Grid item xs={12} sx={{width:'100%'}}>
                    <Grid container justifyContent="space-between" alignItems="center" sx={{width:'100%'}}>
                      <Grid item xs={3} sm={2} md={0.8} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" fontWeight="bold"  fontSize={'16px'}>
                          Plans
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Divider sx={{ borderWidth: '1.7px', borderColor: '#1FE8E8', width: '100%' }} />
                      </Grid>
                      <Grid item xs={3} sm={2} md={1.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Typography variant="caption">
                          {allPlans?.length} results
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container mt={3}  sx={{width:'100%',border:{xs:'1px solid gray',sm:'1px solid gray',md:'none'}}}>
                    <Grid item md={12} sx={{width:'100%'}}>
                      <TableContainer component={Paper} elevation={0} sx={{ maxWidth: '100%', overflow: 'auto', margin:' 0',    '&::-webkit-scrollbar': { /* For Chrome, Safari, Edge */
                          width: '8px',
                          height: '5px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#b0b0b0',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: '#f0f0f0',
                        } }}>
                        <Table  sx={{minWidth:650}} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold', fontSize: { xs: '12px', sm: '14px' } }}>Name</TableCell>
                              <TableCell sx={{ fontWeight: 'bold', fontSize: { xs: '12px', sm: '14px' } }} align="left">
                                Term
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'bold', fontSize: { xs: '12px', sm: '14px' } }} align="left">
                                Frequency
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'bold', fontSize: { xs: '12px', sm: '14px' } }} align="left">
                                Price
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allPlans.slice(0, visibleRows).map(plan => (
                              <TableRow key={plan.mpnId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  <FormGroup>
                                    <FormControlLabel
                                      sx={{ margin: 0, padding: 0 }}
                                      control={
                                        <Checkbox
                                          sx={{
                                            color: '#62c3da',
                                            margin: 0,
                                            padding: '2px',
                                            '&.Mui-checked': {
                                              color: '#62c3da',
                                              margin: 0,
                                              padding: '2px',
                                            },
                                            '& .MuiSvgIcon-root': {
                                              borderRadius: '4px',
                                            },
                                          }}
                                          checked={selectedMpnIds === plan.mpnId}
                                          disabled={selectedMpnIds && selectedMpnIds !== plan.mpnId}
                                          onChange={handleCheckboxChange(plan.mpnId)}
                                        />
                                      }
                                      label={<Typography variant="subtitle2">{plan.displayName}</Typography>}
                                    />
                                  </FormGroup>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="subtitle2" sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                                    {plan.phases[0].type}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="subtitle2" sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                                    {plan.billingPeriod}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="subtitle2" sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                                    INR {plan.appbowlPrice || plan.pricebookMasterPrice || 0}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </Grid>
                    <Grid item xs={12} my={2}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={2} sm={3.5} md={4.6} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Divider sx={{ borderWidth: '1.7px', borderColor: '#FFD83F', width: '100%' }} />
                        </Grid>
                        <Grid item xs={7.2} sm={2.5} md={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            onClick={loadMoreRows}
                            disabled={!isMoreAvailable}
                            sx={{ border: '2px solid #FFD83F', borderRadius: '20px', color: 'black', width:'max-content' }}
                          >
                            {isMoreAvailable ? 'View More Plans' : 'No More Plans'}
                          </Button>
                        </Grid>
                        <Grid item xs={2} sm={3.5} md={4.6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <Divider sx={{ borderWidth: '1.7px', borderColor: '#FFD83F', width: '100%' }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={3} sm={2.3} md={1.1} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2" fontWeight="bold"  fontSize={'16px'}>
                            Add-Ons
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={7.6} md={9.7} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Divider sx={{ borderWidth: '1.7px', borderColor: '#1FE8E8', width: '100%' }} />
                        </Grid>
                        <Grid item xs={3} sm={2} md={1.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <Typography variant="caption">
                            16 results
                          </Typography>
                        </Grid>
                      </Grid>
                  </Grid>
                  <Grid item md={12}>
                    <TableContainer component={Paper} elevation={0}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell fontSize={'14px'}><strong>Plan Name</strong></TableCell>
                            <TableCell align="left" fontSize={'14px'}><strong>Term</strong></TableCell>
                            <TableCell align="left" fontSize={'14px'}><strong>Frequency</strong></TableCell>
                            <TableCell align="left" fontSize={'14px'}><strong>Price</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productDetails.definition_addons?.map((addOns) => (
                            addOns?.plans.map((plan) => (
                            <TableRow key={plan.id}>
                              <TableCell>
                                <FormGroup >
                                  <FormControlLabel
                                      sx={{ margin: 0,padding:0 }}
                                      control={
                                          <Checkbox
                                              
                                              sx={{
                                                  color: '#62c3da',
                                                  margin: 0,
                                                  padding:'2px',
                                                  '&.Mui-checked': {
                                                      color: '#62c3da',
                                                      margin: 0,
                                                      padding:'2px' 
                                                  },
                                                  '& .MuiSvgIcon-root': {
                                                      borderRadius: '4px'
                                                  }
                                              }}
                                          />
                                      }
                                      label={
                                        <Typography variant="subtitle2">
                                            {plan.id}
                                        </Typography>
                                      }
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="left"><Typography variant="subtitle2">{plan.phases[0].type}</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2">{plan.billingPeriod}</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2">{plan.price || 0}</Typography></TableCell>
                            </TableRow>
                          ))
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid> */}
                  <Grid item xs={12}sx={{width:'100%', padding:'0px 5px 30px 5px'}}>
                    <Grid container justifyContent="space-between" alignItems="center" pt={3}>
                      <Grid item xs={3} sm={3} md={1.8} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" fontWeight="bold"  fontSize={'16px'}>
                          Capabilities
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={9} md={10.2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Divider sx={{ borderWidth: '1.7px', borderColor: '#1FE8E8', width: '100%' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {productDetails?.marketing_capabilities?.length && (
                  <Grid container  >
                    <Grid item xs={12} sx={{background:'#fff5cf', padding:'30px'}}>
                      <Grid sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                          <Typography textAlign='center' variant='body1' fontWeight='bold' fontSize={'14px'}>
                          {productDetails?.marketing_capabilities[0]?.title || ""}
                          </Typography>
                      </Grid>
                      <Grid pt={2} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                          <Typography textAlign='center' variant='body2'>
                            {productDetails?.marketing_capabilities[0]?.content || ""}
                          </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid sx={{background:'#ffffff'}}>
                {productDetails?.marketing_capabilities?.length && (
                  <Grid container justifyContent='space-between'>
                        {
                          productDetails.marketing_capabilities[0]?.features.map((el, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <Grid container direction='column' p={3} >
                                <Grid item>
                                  {/* <WifiRoundedIcon /> */}
                                  {/* <img src={el.logo} style={{width:{xs:'15vw',sm:'15vw',md:'8vw'}}} alt="img" /> */}
                                  <Box
                                    component="img"
                                    src={el.logo}
                                    alt="img"
                                    sx={{
                                      width: { xs: '20vw', sm: '20vw', md: '8vw' },
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography variant='subtitle1' fontWeight='bold' fontSize={'14px'}>
                                    {el.displayName}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant='body2'>
                                    {el.description}
                                  </Typography>
                                </Grid>
                              </Grid>
                          </Grid>

                          ))
                        }
                    
                    
                  </Grid>
                )}
                <Grid container sx={{padding:'20px'}}>
                  <Grid item xs={12} >
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item xs={4} sm={3} md={1.8} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" fontWeight="bold" fontSize={'16px'}>
                          Key Features
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={9} md={10.2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Divider sx={{ borderWidth: '1.7px', borderColor: '#1FE8E8', width: '100%' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justifyContent='space-between'>
                  
                {
                        productDetails.marketing_productOverviews?.map((el, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>

                            <Grid container direction='column' p={3} >
                              <Grid item>
                                {/* <WifiRoundedIcon /> */}
                                {/* <img src={el.url} style={{width: '8vw'}} alt="img" /> */}
                                <Box
                                  component="img"
                                  src={el.url}
                                  alt="img"
                                  sx={{
                                    width: { xs: '20vw', sm: '20vw', md: '8vw' },
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant='subtitle1' fontWeight='bold' fontSize={'14px'}>
                                  {el.displayName}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {el.description}
                                </Typography>
                              </Grid>
                            </Grid>
                        </Grid>

                        ))
                      }
                </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} sx={{display:{xs:'none',sm:'none',md:'block'}}} >
              <Grid sx={{padding:'10px 0'}}>
                <Typography variant='h6' fontWeight='bold' fontSize={'22px'}>
                  Summary
                </Typography>
              </Grid>
              <Grid>
                <Grid container direction='column' spacing={1} sx={{background:'#fff',padding:'20px 20px 0px'}}>
                    <Grid item xs={12} sm={12} md={12} sx={{margin:'0 0 12px 0'}}>
                      <Typography variant='body1' fontWeight='bold' fontSize={'14px'}>{productDetails.marketing_displayName}</Typography>
                    </Grid>
                    {/* {selectedPlans?.length ? selectedPlans.map((item , index)=>(
                      <Grid container direction='column' spacing={1} key={item.id}>

                        <Grid item xs={12} sm={12} md={12} pt={1}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item>
                              <Typography variant='body2'>Term:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>{item?.phases[0]?.type}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item>
                              <Typography variant='body2'>Frequency:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>{item?.billingPeriod}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item>
                              <Typography variant='body2'>Price:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>INR {item?.pricebookMasterPrice.toFixed(2)}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item>
                              <Typography variant='body2'>Quantity:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>1</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{margin:'14px 0'}}>
                          <Divider sx={{ borderWidth: '1px', borderColor: 'grey', width: '100%' }} />
                        </Grid>
                      </Grid>

                    )):(
                      <></>
                    )} */}
                    {selectedPlans?.length ? selectedPlans.map((item, index) => (
                        <Grid container direction='column' spacing={1} key={item.id}>
                          <Grid item xs={12} sm={12} md={12} pt={1}>
                            <Grid container direction='row' justifyContent='space-between'>
                              <Grid item>
                                <Typography variant='body2'>Term:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>{item?.phases[0]?.type}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container direction='row' justifyContent='space-between'>
                              <Grid item>
                                <Typography variant='body2'>Frequency:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>{item?.billingPeriod}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container direction='row' justifyContent='space-between'>
                              <Grid item>
                                <Typography variant='body2'>Price:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>INR {(item?.cartPrice || 0).toFixed(2)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                              <Grid item>
                                <Typography variant='body2'>Quantity:</Typography>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems='center' spacing={1}>
                                  <Grid item>
                                    <Grid
                                      sx={{
                                        border: '2px solid #1FE8E8',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding:'2px',
                                        cursor: 'pointer', // Optional: Add a pointer cursor for better UX
                                      }}
                                      onClick={() => handleQuantityChange(index, 'decrease')}
                                    >
                                      <RemoveIcon sx={{ fontSize: '18px'}} /> {/* Thicker icon */}
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant='body2' fontWeight="bold">{item.quantity}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Grid
                                      sx={{
                                        border: '2px solid #1FE8E8',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding:'2px',
                                        cursor: 'pointer', // Optional: Add a pointer cursor for better UX
                                      }}
                                      onClick={() => handleQuantityChange(index, 'increase')}
                                    >
                                      <AddIcon sx={{ fontSize: '18px'}} /> {/* Thicker icon */}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} sx={{ margin: '14px 0' }}>
                            <Divider sx={{ borderWidth: '1px', borderColor: 'grey', width: '100%' }} />
                          </Grid>
                        </Grid>
                      )) : (
                        <Typography variant='body2' textAlign='center'>No plans selected.</Typography>
                      )}

                    {/* <Grid item xs={12} sm={12} md={12} sx={{margin:'14px 0'}}>
                      <Divider sx={{ borderWidth: '1px', borderColor: 'grey', width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{margin:'0 0 12px 0'}} >
                      <Typography variant='body1' fontWeight='bold' fontSize={'14px'}>1-year Extended Warrenty</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} pt={1} >
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='body2'>Term:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>Unlimited</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='body2'>Frequency:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>Monthly</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='body2'>Price:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>INR 100</Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}













                    {/* <Grid item xs={12} sm={12} md={12}>
                      <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='body2'>Quantity:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>Unlimited</Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={12} mt={6} >
                      <Button sx={{border:'2px solid #FFD83F',borderRadius:'20px' , color:'black',fontWeight:'bold', fontSize: '14px'}} fullWidth>Compare</Button>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} pb={2}>
                      <Button 
                        sx={{border:'2px solid #1FE8E8',
                          background:'#1FE8E8',borderRadius:'20px' , 
                          color:'black',fontWeight:'bold',marginTop: "6px", fontSize: '14px'}} 
                          fullWidth
                          disabled={!selectedPlans.length}
                          onClick={handleAddtoCart}
                        >
                          Add to Cart
                      </Button>
                    </Grid>
                    {isEnable && (
                    <Grid item xs={12} sm={12} md={12} pb={2}>
                      <Button 
                        sx={{border:'2px solid #1FE8E8',
                          background:'#1FE8E8',borderRadius:'20px' , 
                          color:'black',fontWeight:'bold',marginTop: "6px", fontSize: '14px'}} 
                          fullWidth
                          onClick={handleCartNav}
                        >
                          GO to Cart
                      </Button>
                    </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    }
      {/* <Grid container spacing={3} sx={{width:{xs:'98%',sm:'98%',md:'80%'},margin:'0px auto'}}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ padding: '16px', marginBottom: '24px' }}>
            <Typography variant="h5" sx={{ marginBottom: '16px' }}>Explore</Typography>
            <Typography variant="h6" sx={{ marginBottom: '8px' }}>Plans</Typography>
            <Grid container spacing={2}>
              {plans.map((plan, index) => (
                <Grid item xs={12} key={index}>
                  <Box display="flex" justifyContent="space-between">
                    <Checkbox />
                    <Typography>{plan.name}</Typography>
                    <Typography>{plan.term}</Typography>
                    <Typography>{plan.frequency}</Typography>
                    <Typography>{plan.price}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Typography variant="h6" sx={{ marginTop: '24px', marginBottom: '8px' }}>Add-Ons</Typography>
            <Grid container spacing={2}>
              {addons.map((addon, index) => (
                <Grid item xs={12} key={index}>
                  <Box display="flex" justifyContent="space-between">
                    <Checkbox />
                    <Typography>{addon.name}</Typography>
                    <Typography>{addon.term}</Typography>
                    <Typography>{addon.frequency}</Typography>
                    <Typography>{addon.price}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: '16px', marginBottom: '24px' }}>
            <Typography variant="h5" sx={{ marginBottom: '16px' }}>Summary</Typography>
            <Typography variant="body1">Please select a plan from the left to see details.</Typography>
            <Box sx={{ marginTop: '16px' }}>
              <Button variant="contained" fullWidth>Add to Cart</Button>
            </Box>
          </Paper>
        </Grid>
      </Grid> */}

      {/* <Box sx={{ backgroundColor: '#f5f5f5', padding: '16px', marginBottom: '24px' }}>
        <Typography variant="h5" sx={{ marginBottom: '16px' }}>Key Features</Typography>
        <Grid container spacing={2}>
          {keyFeatures.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Typography variant="subtitle1" fontWeight="bold">{feature.title}</Typography>
              <Typography variant="body2">{feature.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box> */}
{/* 
      <Box sx={{ marginBottom: '24px' }}>
        <Typography variant="h5" sx={{ marginBottom: '16px' }}>Capabilities</Typography>
        <Grid container spacing={2}>
          {capabilities.map((capability, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Typography variant="subtitle1" fontWeight="bold">{capability.title}</Typography>
              <Typography variant="body2">{capability.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box> */}
    
    </Grid>
  );
};

export default MarketplacePage;
