import React, { useState } from "react";
import { Grid, Tabs, Tab, Box } from "@mui/material";
import Subscription from "../components/user/subscription";
import Dashboard from "../components/user/dashboard";
import Quotes from "../components/user/quotes";
import Orders from "../components/user/orders";
import Reports from "../components/user/reports";
import Invoice from "../components/user/invoice";
import WQM from "../components/user/wqm";
import Support from "../components/user/Support";

const UserPortal = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Components for each tab
  const tabComponents = [
    <Dashboard />,
    <Subscription />,
    <Orders />,
    <Support />
  ];

  return (
    <Grid sx={{ background: '#f2f3f5' }} p={4}>
      <Grid container gap={3} justifyContent="center" alignItems="center" direction="column" >
        <Grid item  sx={{maxWidth:{xs:'100% !important',sm:'100% !important',md:'100% !important',overflow:'auto'}}}>
          <Grid container direction="row">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="user portal tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                background: '#fff',
                borderRadius: '50px',
                boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                '& .MuiTabs-indicator': {
                  display: 'none', // Hide the default indicator
                },
              }}
            >
            {/* ['Subscription', 'Dashboard', 'Quotes', 'Orders', 'Reports', 'Invoice', 'WQM'] */}
              {['Profile', 'Subscription', 'Orders', "Support"].map((label, index) => (
                <Tab
                  key={label}
                  label={label}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '50px',
                    color: activeTab === index ? '#fff !important' : '#000',
                    fontWeight:'bold',
                    padding:'0px 50px',
                    backgroundColor: activeTab === index ? '#6bd4ed' : 'transparent', // Blue background for active tab
                    '&:hover': {
                      backgroundColor: activeTab === index ? '#4ab1c2' : '#e0e0e0', // Slightly darker blue on hover if active
                    },
                  }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>

        {/* Second Grid item with content based on selected Tab */}
        <Grid item sx={{width:'90%',background:'#fff' ,boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 12px',borderRadius:'8px'}} p={3}>
          <Box>
            {tabComponents[activeTab]}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserPortal;
