import React, {useEffect, useState } from "react";
import { Grid, Typography, Button, Divider } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { toast } from "react-toastify";
import { checkoutCart } from "../../apiCalls";
import CircularProgress from '@mui/material/CircularProgress';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Drawer, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropagateLoader from "react-spinners/PropagateLoader";
import ConfigComponent from "./config";
import { css } from "@emotion/react";

import { getCatrItems, deleteCartItem, placeSOOrder } from "../../apiCalls";

const Cart = () => {

    const [cartData ,setCartData] = useState([]);

    const [isLoading , setIsLoading] = useState(false);

    const [isPageLoad , setIsPageLoad ] = useState(false);

    const [pageLoad , setPageLoad ] = useState(false);

    const [userDetails , setUserDetails ] = useState();

    console.log(userDetails , "mmmmmmmmmmmmmmmmmmmmmmmmmmmmmm")

    const [reRun , setReRun ] = useState(false);

    useEffect(() => {
        const savedUser = localStorage.getItem('appbowlUser');
        if (savedUser) {
          const user = JSON.parse(savedUser);
          setUserDetails(user);
        }else{
            toast.error("Please Login to Continue")
            setTimeout(() => {
              window.location.href = "/home";
            }, 2000);
        }
      }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }, []);

      
    useEffect( () => {
        setIsPageLoad(true)
        const getProduct = async () => {
          try {

            const savedUser = JSON.parse(localStorage.getItem('appbowlUser'))

            const data = await getCatrItems(savedUser.id);
            if(data.success){            
                setCartData(data);
                setIsPageLoad(false)
            }else{
                setIsPageLoad(false)
                toast.error(data.message)
            }

          } catch (err) {
            setIsPageLoad(false)
            console.error('Error fetching testimonials:', err);
          }
        }
    
        getProduct()
    }, [reRun]);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setIsDrawerOpen(open);
    };


    // const handlePlaceOrder = async () => {

    // const payload = {
    //     "amount": 500,
    //     "currency": "INR",
    //     "receipt": "receipt#123"
    //   }
        
    // try{
    //   const data = await checkoutCart(payload);

    //   if(data.success){

    //     const options: RazorpayOrderOptions = {
    //         key: "rzp_test_qqahxA25S3Rwpu",
    //         amount: data?.order?.amount, // Amount in paise
    //         currency: data?.order?.currency,
    //         name: "Test Company",
    //         description: "Test Transaction",
    //         order_id: data?.order?.id , // Generate order_id on server
    //         handler: (response) => {
    //           console.log(response);
    //           window.location.href='/order/success';
    //         },
    //         prefill: {
    //           name: "John Doe",
    //           email: "john.doe@example.com",
    //           contact: "9999999999",
    //         },
    //         theme: {
    //           color: "#F37254",
    //         },
    //       };
      
    //       const razorpayInstance = new Razorpay(options);
    //       razorpayInstance.open();
    //   }else{
    //     toast.error(data.message || "Something went Wrong")
    //   }

    // //   setProductDetail(data);
    // } catch (err) {
    //   toast.error('Failed to checkout cart . Please try again later.');
    //   console.error('Error fetching testimonials:', err);
    // }
    // }


    const handleCartDelete = async (e) => {
            e.preventDefault()
            // localStorage.setItem('selectedPlans', JSON.stringify(selectedPlans));

            console.log("llll", cartData);
            
            
            try {
              const dataToSend = {
                productId : cartData.cart.productId, 
                userId : cartData.cart.userId
              }
              const response = await deleteCartItem(dataToSend)
              if (response.success) {
                setReRun(!reRun)
                toast.success("cart item deleted successfully!");
    
              }else{
                toast.error("Something Went Wrong")
              }
            
            } catch (error) {
              console.error('Error:', error);
            }
          
    }


    const handleSuccessCartDelete = async () => {
        
        try {
          const dataToSend = {
            productId : cartData.cart.productId, 
            userId : cartData.cart.userId
          }
          const response = await deleteCartItem(dataToSend)
          if (response.success) {
            console.log(response)
          }else{
            toast.error("Something Went Wrong")
          }
        
        } catch (error) {
          console.error('Error:', error);
        }
      
}



    const handlePlaceOrder = async () => {

        
        setIsLoading(true)
        const payload = {
          amount: cartData?.cart?.cart_items[0]?.cartPrice,
          currency: "INR",
          receipt: "receipt#123",
        };
      
        try {
          const data = await checkoutCart(payload);
      
          if (data.success) {
            const options = {
              key: "rzp_test_qqahxA25S3Rwpu", // Replace with your Razorpay Key ID
              amount: data?.order?.amount, // Amount in paise
              currency: data?.order?.currency,
              name: "AppBowl",
              description: userDetails?.name+"_checkout_24565",
              order_id: data?.order?.id, // Order ID from your backend
              handler: async function (response) {
                try {
                    setPageLoad(true)
                    const payLoad = {
                        customerId: userDetails?.customerId,
                        order: {
                          displayName: userDetails?.name + "Order",
                          orderItems: [
                            {
                              referenceId: "my-order-item-002",
                              productId: cartData?.cart?.productId ,
                              // "productId": "AIP-pimynce",
                              // "skuId": "Azure-Information-Protection-Pre",
                              // "planId": "Monthly-Plan",
                              skuId: cartData?.cart?.cart_items[0]?.skuId,
                              planId: cartData?.cart?.cart_items[0]?.id,
                              action: "CREATE",
                              quantity: cartData?.cart?.cart_items[0]?.quantity,
                              providerName: "AZURE",
                              endCustomerPO: "PO_1234",
                              resellerPO: "Reseller_PO_111",
                              attributes: [
                                {
                                  name: "domainName",
                                  value: "myuniverse"
                                }
                              ]
                            }
                          ]
                        }
                      }
                    const response = await placeSOOrder(payLoad)
                    if (response.success) {
                      setPageLoad(false);
                      try {
                        const dataToSend = {
                          productId : cartData.cart.productId, 
                          userId : cartData.cart.userId
                        }
                        const response = await deleteCartItem(dataToSend)
                        if (response.success) {
                            toast.success("Order Created Successfully");
                            setTimeout(() => {
                              window.location.href = "/order/success";
                            }, 1000);
                        }else{
                          toast.success("Order Created Successfully");
                          setTimeout(() => {
                            window.location.href = "/order/success";
                          }, 1000);
                        }
                      
                      } catch (error) {
                        console.error('Error:', error);
                      }
                    }else{
                      toast.error(response.message);
                      setPageLoad(false)
                      setTimeout(() => {
                        window.location.href = "/order/failed";
                      }, 1000);
                    }
                  
                  } catch (error) {
                    console.error('Error:', error);
                    setPageLoad(false);
                  }


                  
               //   try {
                //     // Call the order API after successful payment
                //     const orderApiResponse = await fetch('/api/orders/create-order', {
                //         method: 'POST',
                //         headers: {
                //             'Content-Type': 'application/json',
                //         },
                //         body: JSON.stringify({
                //             "customerId": 582374,
                //             "order": ""
                //         }),
                //     });

                //     if (orderApiResponse.ok) {
                //     const result = await orderApiResponse.json();
                //         console.log('Order API Response:', result);
                //         window.location.href = "/order/success";
                //     } else {
                //         console.error('Order API failed:', await orderApiResponse.text());
                //         toast.error('Failed to update order status. Please contact support.');
                //     }
                // } catch (error) {
                //     console.error('Error while calling order API:', error);
                //     toast.error('An error occurred. Please try again.');
                // }
              },
              prefill: {
                name: userDetails?.name , // Example prefilled name
                email: userDetails?.email , // Example prefilled email
                contact: userDetails?.phone_number , // Example prefilled contact
              },
              notes: {
                address: 'Appbowl Corporate Office',
              },
              theme: {
                color: "#F37254", // Razorpay checkout theme color
              },
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open();
            setIsLoading(false)
          } else {
            toast.error(data.message || "Something went wrong");
            setIsLoading(false)
          }
        } catch (err) {
          toast.error("Failed to checkout cart. Please try again later.");
          console.error("Error during checkout:", err);
          setIsLoading(false)
        }
      };
      
      const loaderStyles = css`
        display: block;
        margin: 0 auto; /* centers the loader horizontally */
    `;
    return(
        <>
            <ConfigComponent isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
            {pageLoad && (
                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    zIndex: 1000,
                }}
                >
                    <PropagateLoader
                        color="#1FE8E8"  
                        width={400}     
                        height={6}     
                    />
                </Box>
            )}
            <Grid container sx={{background:'#f8f8f8',minHeight:'800px'}} >
                <Grid 
                sx={{
                    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url(https://png.pngtree.com/thumb_back/fh260/background/20201028/pngtree-abstract-technology-background-technical-electric-image_443494.jpg)',
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                }}
                >
                    <Grid container direction='column' spacing={1.5} sx={{width:{xs:'98%',sm:'98%',md:'80%'},margin:'60px auto'}}>
                        <Grid item>
                            <Typography variant="h4" fontWeight='bold' color="#fff">Cart</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2"  color="#BABABA">Review your selected software solutions and proceed to a seamless checkout. Your favorite tools are just a step away!</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {isPageLoad ? (
                    <Grid sx={{width:{xs:'98%',sm:'98%',md:'80%'},margin:'0px auto', display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <PropagateLoader
                            color="#1FE8E8"  
                            width={300}     
                            height={4}     
                        />
                    </Grid>
                ):(
                 
                    <Grid sx={{width:{xs:'98%',sm:'98%',md:'80%'},margin:'0px auto', display: !cartData?.product && 'flex', alignItems: !cartData?.product && 'center',justifyContent: !cartData?.product && 'center'}}>
                        {cartData?.product ? (
                            <Grid container justifyContent='space-between' direction='row'>
                                <Grid item md={9.5}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item sx={{padding:'10px 0'}}>
                                            <Typography variant='h6' fontWeight='bold'>
                                            Items
                                            </Typography>
                                        </Grid>
                                
                                    </Grid>
                                    <Grid container direction='row'>
                                        <Grid item md={12} pt={2.5}>
                                            <Grid container direction='row' justifyContent='space-between' sx={{background:'#fff',boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',padding:'20px',borderRadius:'10px'}}>
                                                <Grid item md={2.5} sx={{display:'flex',alignItems:'center'}} p={2}>
                                                    <img src={cartData.product?.marketing_defaultImage_content} alt="product" width='100%'/>
                                                </Grid>
                                                <Grid item md={9.5}>
                                                    <Grid container >
                                                        <Grid item xs={12} pt={0.7} pb={0.7}>
                                                            <Grid container justifyContent='space-between' direction='row'>
                                                                <Grid item md={4}>
                                                                    <Grid container direction='column'>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="body2" fontWeight='bold'>{cartData.cart?.productId}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="subtitle2" onClick={toggleDrawer(true)} sx={{cursor:'pointer'}}>Click here to Configure</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* <Grid item md={1.6}>
                                                                    <Typography variant="body2" fontWeight='bold'>+/-</Typography>
                                                                </Grid> */}
                                                                <Grid item md={2}>
                                                                    <Typography variant="body2" >{cartData.cart?.cart_items[0].phases[0].type}</Typography>
                                                                </Grid>
                                                                <Grid item md={1.5}>
                                                                    <Typography variant="body2"  >{cartData.cart?.cart_items[0].billingPeriod}</Typography>
                                                                </Grid>
                                                                <Grid item md={1.7}>
                                                                    <Typography variant="body2">INR {cartData.cart?.cart_items[0].cartPrice}</Typography>
                                                                </Grid>
                                                                <Grid item md={1.1}>
                                                                    <Typography variant="body2" >< DeleteIcon sx={{cursor:'pointer'}} onClick={handleCartDelete} /></Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Grid item xs={12} pt={0.7} pb={0.7}>
                                                            <Grid container justifyContent="space-between" alignItems="center">
                                                                <Grid item xs={5} sm={3} md={1.6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography variant="body2" fontWeight="bold">
                                                                        ADD ONS
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sm={9} md={10.4} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Divider sx={{ borderWidth: '1px', borderColor: '#1FE8E8', width: '100%' }} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid> */}
                                                        { cartData.cart?.cart_items[0].supportPlan.length >= 0 ?? (
                                                        <Grid item xs={12} pt={0.7} pb={0.7}>
                                                            <Grid container justifyContent='space-between' direction='row'>
                                                                <Grid item md={4}>
                                                                    <Typography variant="body2" fontWeight='bold'>1-Year Extended Warenty</Typography>
                                                                </Grid>
                                                                {/* <Grid item md={1.6}>
                                                                    <Typography variant="body2" fontWeight='bold'>+/-</Typography>
                                                                </Grid> */}
                                                                <Grid item md={1.6}>
                                                                    <Typography variant="body2">Unlimited</Typography>
                                                                </Grid>
                                                                <Grid item md={1.5}>
                                                                    <Typography variant="body2">Monthly</Typography>
                                                                </Grid>
                                                                <Grid item md={1.7}>
                                                                    <Typography variant="body2">INR 500</Typography>
                                                                </Grid>
                                                                <Grid item md={1.5}>
                                                                    <Typography variant="body2"><DeleteIcon /></Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={2.4}>
                                    <Grid sx={{padding:'10px 0'}}>
                                        <Typography variant='h6' fontWeight='bold'>
                                        Summary
                                        </Typography>
                                    </Grid>
                                    {/* <Grid container direction='column'>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant="standard" sx={{minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-standard-label">View Price Breakdown for</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={age}
                                                onChange={handleChange}
                                                label="View Price Breakdown for"
                                                size="small"
                                                >
                                                <MenuItem value="all">
                                                
                                                    All Products
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                    <Grid pt={3.2}>
                                        <Grid container direction='column' spacing={0.5} sx={{background:'#fff',padding:'20px'}}>
                                            <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant='body2' fontWeight='bold'>Price Breakdown for All Products</Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} sx={{padding:'10px 0'}}>
                                            <Divider sx={{ borderWidth: '1.8px', borderColor: 'grey', width: '100%' }} />
                                            </Grid>
                                            {/* <Grid item xs={12} sm={12} md={12} >
                                            <Typography variant='body1' fontWeight='bold'>1-year Extended Warrenty</Typography>
                                            </Grid> */}
                                            <Grid item xs={12} sm={12} md={12} pt={1}>
                                            <Grid container direction='row' justifyContent='space-between'>
                                                <Grid item>
                                                <Typography variant='body2'>Term:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2'>{cartData.cart?.cart_items[0].phases[0].type}</Typography>
                                                </Grid>
                                            </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                            <Grid container direction='row' justifyContent='space-between'>
                                                <Grid item>
                                                <Typography variant='body2'>Frequency:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2'>{cartData.cart?.cart_items[0].billingPeriod}</Typography>
                                                </Grid>
                                            </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                            <Grid container direction='row' justifyContent='space-between'>
                                                <Grid item>
                                                <Typography variant='body2'>Price:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2'>{cartData.cart?.cart_items[0].cartPrice}</Typography>
                                                </Grid>
                                            </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                            <Grid container direction='row' justifyContent='space-between'>
                                                <Grid item>
                                                <Typography variant='body2'>Quantity:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2'>{cartData.cart?.cart_items[0].quantity}</Typography>
                                                </Grid>
                                            </Grid>
                                            </Grid>                                    
                                        </Grid>
                                    </Grid>
                                    <Grid pt={2}>
                                        <Grid container>
                                            <Grid item xs={!2} md={12}>
                                                <Button sx={{border:'2px solid #1FE8E8',background:'#1FE8E8',borderRadius:'20px' , color:'black',fontWeight:'bold'}} fullWidth onClick={handlePlaceOrder} >{isLoading ? <CircularProgress /> : "Place Order" }</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </Grid>
                        ):(
                            <Grid sx={{width:{xs:'98%',sm:'98%',md:'80%'},margin:'0px auto', display:'flex', flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                                <Box
                                    component="img"
                                    src="https://cdn-icons-png.flaticon.com/512/11010/11010851.png"
                                    alt="Icon"
                                    sx={{
                                    width: { xs: '100px', sm: '100px', md: '150px' }, // Responsive widths
                                    height: 'auto', 
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center'
                                    }}
                                />
                                <Typography variant='h5' >Oops! Your cart is empty.</Typography>
                              </Grid>
                        )}
                       
                    </Grid>
                )}
                
            </Grid>
        </>
    )
}

export default Cart;